<template>
  <div class="df-flex-sm df-flex-col df-header-title">
    <h2 v-if="subtitle" class="header__subtitle">{{ subtitle }}</h2>
    <h1 class="header__title">{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: 'DfHeaderTitle',

  props: {
    title: {
      required: true,
      type: String,
    },

    subtitle: {
      default: '',
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.df-header-title {
  .header__subtitle {
    @include label-x-small;
    color: $color-text-secondary;
  }
  .header__title {
    @include heading-x-large;
    color: $color-text-secondary;
  }
}
</style>
