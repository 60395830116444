<template>
  <div
    class="df-flex-m df-flex-col justify-center align-start show-release-date-error"
  >
    <df-button
      left-icon="arrow-left"
      variant="text-green"
      @click="goBackToLogin"
    >
      {{ $t('login.back') }}
    </df-button>
    <h1 class="error__header">
      {{ $t('login.error_release_date', { date: releaseDate }) }}
    </h1>
    <p class="error__info-text">{{ $t('login.error_message_release_date') }}</p>
  </div>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'

export default {
  name: 'ShowReleaseDateError',

  components: {
    DfButton,
  },

  props: {
    releaseDate: {
      required: true,
      type: String,
    },
  },

  methods: {
    goBackToLogin() {
      this.$emit('go-back-to-login')
    },
  },
}
</script>

<style lang="scss" scoped>
.show-release-date-error {
  height: fit-content;

  .error__header {
    @include heading-medium;
    color: $color-text-secondary;
  }
  .error__info-text {
    @include paragraph-large;
    color: $color-text-neutral;
  }
}
</style>
