<template>
  <v-dialog max-width="471" :value="true">
    <div class="df-flex-xl df-flex-col align-center payment-approved-modal">
      <font-awesome-icon
        class="modal__icon"
        icon="check-circle"
        :color="colorIconPrimary"
      />
      <v-btn class="modal__close-btn" icon @click="closeModal">
        <v-icon small> mdi-close </v-icon>
      </v-btn>
      <div class="df-flex-sm df-flex-col modal__text">
        <h1>Parabéns! Seu pagamento foi aprovado.</h1>
        <p>
          Bem vindo(a) ao plano
          <span>{{ planInfos[planPriority].name }}</span
          >!
          {{ planInfos[planPriority].approved_payment_message }}
        </p>
      </div>
      <df-button width="100%" @click="redirect">Continuar</df-button>
    </div>
  </v-dialog>
</template>

<script>
import colors from '@/assets/styles/__colors.module.scss'
import DfButton from '@/lib/components/Button/DfButton.vue'
import planLevels from '@/modules/plans/data/planLevels'
import { planInfos } from '@/modules/payment/data/planPt'

export default {
  name: 'PaymentApprovedModal',

  components: {
    DfButton,
  },

  props: {
    planPriority: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      colorIconPrimary: colors.colorIconPrimary,
      planInfos,
    }
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },
    redirect() {
      if (this.planPriority == planLevels.light) {
        this.$router.push({
          path: '/catalog',
        })
      } else {
        this.$router.push({
          path: '/home',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.payment-approved-modal {
  background-color: $color-background-white;
  padding: $spacing-xxl $spacing-xl;
  position: relative;

  .modal__icon {
    height: 40px;
    width: 40px;
  }
  .modal__close-btn {
    position: absolute;
    top: 20px;
    right: 10px;
  }
  .modal__text {
    h1 {
      @include heading-small;
      color: $color-text-secondary;
      text-align: center;
    }
    p {
      @include paragraph-medium;
      color: $color-text-neutral;
      text-align: center;
    }
    span {
      text-transform: capitalize;
    }
  }
}
</style>
