<template>
  <div class="df-flex-l df-flex-col brazil-chicago-card">
    <div class="df-flex-none align-center">
      <div class="df-flex-m df-flex-col flex-grow-1">
        <h4 class="card__date">
          {{ quotation.date.formatDate() }}
        </h4>
        <div class="df-flex-xs df-flex-col">
          <div class="df-flex-sm align-baseline">
            <span class="card__currency-usd">US$</span>
            <div>
              <span class="card__usd-value">
                {{ formatPrice(quotation.close)[0] }}
              </span>
              <span
                v-if="formatPrice(quotation.close)[1]"
                class="card__usd-cents"
              >
                ,{{ formatPrice(quotation.close)[1] }}
              </span>
            </div>
            <div>
              <font-awesome-icon
                v-if="quotation.percentage_variation >= 0"
                icon="arrow-up"
                size="xs"
                :color="colorIconSuccess"
              />
              <font-awesome-icon
                v-else
                icon="arrow-down"
                size="xs"
                :color="colorIconDanger"
              />
              <span class="card__percentage-variation">
                {{ porcentageVariation }}%
              </span>
            </div>
          </div>
          <div class="card__info-ordinance">
            {{ $t('closure') }} (US$ / {{ quotation.contract_type }})
          </div>
        </div>
      </div>
      <div
        v-if="showDollarquotation"
        class="df-flex-m df-flex-col flex-grow-1 card__dol-quotation"
      >
        <div class="quotation__dollar-rate">{{ this.$t('dollar_rate') }}</div>
        <div class="quotation__brl-quotation">
          <span class="brl__currency">{{ $t('moeda') }}</span>
          <span class="brl__convert">
            {{ formatPriceCurrentDollarValue(dollarQuotation['usd_brl']) }}
          </span>
        </div>
      </div>
    </div>
    <v-select
      v-model="quotation"
      item-value="id"
      item-text="name"
      hide-details
      outlined
      return-object
      :items="quotationsSelect"
      :label="$t('commodity')"
    />
  </div>
</template>

<script>
import colors from '@/assets/styles/__colors.module.scss'
import { currentLanguage, LANG } from '@/language'

export default {
  name: 'BrazilChicagoCard',

  data() {
    return {
      colorIconDanger: colors.colorIconDanger,
      colorIconSuccess: colors.colorIconSuccess,
      currentLanguage: currentLanguage(),
      dollarQuotation: 0,
      quotations: [],
      quotation: {
        id: null,
        name: null,
        percentage_variation: 0,
        contract_type: null,
        close: 0,
        date: new Date().toLocaleDateString(),
      },
    }
  },

  async created() {
    this.$emit('loading-brazil-chicago', true)
    await Promise.allSettled([this.setQuotations(), this.setDollarQuotation()])
    this.$emit('loading-brazil-chicago', false)
  },

  watch: {
    quotations(val, old) {
      if (val !== old) {
        this.quotation = val[0]
      }
    },
  },

  computed: {
    quotationsSelect() {
      return [...this.quotations].sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )
    },
    porcentageVariation() {
      return this.quotation.percentage_variation
        ? this.quotation.percentage_variation.toFixed(1).replace('-', '')
        : 0
    },
    showDollarquotation() {
      return this.currentLanguage !== LANG.EN
    },
  },

  methods: {
    async setQuotations() {
      try {
        const { data } = await this.$api.quotations.getAllOrdinaces()
        this.quotations = data
      } catch (error) {
        console.error(error)
      }
    },
    async setDollarQuotation() {
      try {
        const { data } = await this.$api.quotations.getDollarQuotation()
        this.dollarQuotation = data
      } catch (error) {
        console.error(error)
      }
    },
    formatPrice(price) {
      if (this.currentLanguage === LANG.PT) {
        return this.$currency(price).replace('R$', '').split(',')
      } else {
        return this.$currency(price).replace('$', ' ').split('.')
      }
    },
    formatPriceCurrentDollarValue(price) {
      if (this.currentLanguage === LANG.PT) {
        return this.$currency(price).replace('R$', '')
      } else {
        return this.$currency(price).replace('$', ' ')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.brazil-chicago-card {
  padding: $spacing-l;

  .card__date {
    @include label-x-small;
    color: $color-text-neutral;
    font-weight: 600;
    text-transform: uppercase;
  }
  .card__currency-usd {
    @include paragraph-large;
    color: $color-text-disabled;
  }
  .card__usd-value {
    @include heading-large;
    font-weight: 600;
  }
  .card__usd-cents {
    @include label-small;
    font-weight: 600;
    text-transform: uppercase;
  }
  .card__percentage-variation {
    @include label-x-small;
    font-weight: 600;
  }
  .card__info-ordinance {
    @include label-x-small;
    color: $color-text-neutral;
  }
  .card__dol-quotation {
    .quotation__dollar-rate {
      @include label-x-small;
      color: $color-text-neutral;
    }
    .quotation__brl-quotation {
      .brl__currency {
        @include label-x-small;
        font-weight: 600;
        color: $color-text-disabled;
      }
      .brl__convert {
        @include label-small;
        font-weight: 600;
      }
    }
  }
}
</style>
