<template>
  <div class="card-info-plan">
    <div class="df-flex-l df-flex-l-m align-center">
      <div class="df-col-4 df-col-12-m card__title">
        <h1>{{ $t('card_info_plan.title_main') }}</h1>
        <p>{{ $t('card_info_plan.title_description') }}</p>
      </div>
      <div class="df-col-4 df-col-12-m card__subtitle">
        <h2>{{ $t('card_info_plan.subtitle_main') }}</h2>
        <div v-if="!!cheapestPrice" class="price">
          <p>
            {{ $t('card_info_plan.money_unit') }}
            <span class="price__value">{{ formatNumber(cheapestPrice) }}</span>
            {{ $t('card_info_plan.per_month') }}
          </p>
        </div>
      </div>
      <df-button
        class="df-col-4 df-col-12-m"
        flank="fa-pull-right pl-1 fa-1x"
        icon="arrow-right"
        @click="togglePlans"
      >
        {{ $t('card_info_plan.button') }}
      </df-button>
    </div>
  </div>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import PlanMixin from '@/components/PlanMixin.vue'
import { logEvent, events } from '@/services/analytics'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CardInfoPlan',

  mixins: [PlanMixin],

  components: { DfButton },

  watch: {
    currentLanguage: {
      handler(language) {
        this.setPlans(language)
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters('plans', ['cheapestPrice']),
    currentLanguage() {
      return this.$currentLanguage()
    },
  },

  methods: {
    ...mapActions('plans', ['setPlans']),
    formatNumber(value) {
      if (value && ['pt', 'es'].includes(this.currentLanguage)) {
        return value.replace('.', ',')
      } else {
        return value
      }
    },
    togglePlans() {
      logEvent(events.plansModule.clicked_see_plans)
      this.$router.replace({
        path: '/plans',
      })
      this.$emit('close-modal')
    },
  },
}
</script>

<style lang="scss" scoped>
.card-info-plan {
  border-radius: $border-radius-m;
  border: 1px solid $color-border;
  background-color: $color-background-light;
  padding: $spacing-m $spacing-xl;

  .card__title {
    h1 {
      @include heading-x-small;
    }
    p {
      @include paragraph-x-small;
      color: $color-text-neutral;
    }
  }
  .card__subtitle {
    h2 {
      @include label-x-small;
      color: $color-text-disabled;
      text-transform: uppercase;
    }
    .price {
      height: 44px;
      width: 132px;
      border-radius: $border-radius-sm;
      background-color: $color-background-white;
      padding: $spacing-sm $spacing-m;

      @include d(m) {
        width: 100%;
      }
      p {
        @include label-x-small;
        color: $color-text-disabled;

        .price__value {
          @include heading-medium;
          color: $color-text-primary;
        }
      }
    }
  }
}
</style>
