<template>
  <div
    class="df-flex-l df-flex-col align-center justify-center disabled-quotation-card"
  >
    <h1 class="card__title">
      {{ $t('no_data_available') }}
    </h1>
    <p class="card__subtitle">
      {{ $t('data_available_to_farm_enabled') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'DisabledQuotationCard',
}
</script>

<style lang="scss" scoped>
.disabled-quotation-card {
  height: 196px;
  padding: $spacing-xl $spacing-l;

  .card__title {
    @include heading-x-small;
  }
  .card__subtitle {
    @include paragraph-medium;
    color: $color-text-neutral;
    text-align: center;
    max-width: 250px;
  }
}
</style>
