import { render, staticRenderFns } from "./DfPageHeader.vue?vue&type=template&id=345dd515&scoped=true"
import script from "./DfPageHeader.vue?vue&type=script&lang=js"
export * from "./DfPageHeader.vue?vue&type=script&lang=js"
import style0 from "./DfPageHeader.vue?vue&type=style&index=0&id=345dd515&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "345dd515",
  null
  
)

export default component.exports