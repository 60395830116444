<template>
  <v-dialog width="393" :value="true" @click:outside="closeModal">
    <div class="df-flex-xl df-flex-col light-plan-payment-modal">
      <section class="df-flex-sm df-flex-col modal__header">
        <h1>Plano {{ planInfos[planPriority].name }}</h1>
        <p>
          {{ planInfos[planPriority].description }}
        </p>
      </section>
      <section class="df-flex-m df-flex-col modal__content">
        <h2>Escolha seu plano</h2>
        <div v-if="loadingPlans" class="df-flex-l df-flex-col">
          <v-skeleton-loader
            v-for="(_, index) in vindiPlans.length || 3"
            :key="index"
            height="80px"
            type="image"
            width="auto"
          />
        </div>
        <div v-else-if="!vindiPlans.length" class="no-content">
          Não há planos para o modelo {{ planInfos[planPriority].name }} no
          momento
        </div>
        <div
          v-for="plan in vindiPlans"
          :key="plan.id"
          @click="selectPlan(plan.id)"
        >
          <plan-payment-card
            :frequency="plan.interval_name"
            :is-selected="isSelected(plan.id)"
            :most-popular="plan.interval_name === 'Anual'"
            :price="plan.plan_items[0].pricing_schema.short_format"
            :text="planTexts[plan.interval_name]"
          />
        </div>
        <df-button
          :disabled="disableButton"
          :loading="loadingPlans"
          @click="redirectToPayment"
        >
          Continuar
        </df-button>
        <df-button variant="text-green" @click="redirectToPlans">
          Ver todos os planos
        </df-button>
        <p class="modal__cancel">Cancele a qualquer momento.</p>
        <p class="modal__terms">
          A assinatura renova automaticamente, a menos que a renovação
          automática seja desativada por pelo menos 24 horas antes do período de
          cobrança. Consulte nossos
          <a href="" target="_blank">Termos</a> para mais informações sobre o
          cancelamento e reembolso.
        </p>
      </section>
    </div>
  </v-dialog>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import PlanPaymentCard from '@/modules/payment/components/PlanPaymentCard.vue'
import paymentService from '@/modules/payment/services/paymentService'
import { planInfos, planTexts } from '@/modules/payment/data/planPt'

export default {
  name: 'PlanPaymentChoicesModal',

  components: {
    DfButton,
    PlanPaymentCard,
  },

  props: {
    planPriority: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      loadingPlans: false,
      planInfos,
      planTexts,
      selectedPlan: {},
      vindiPlans: [],
    }
  },

  async created() {
    this.loadingPlans = true
    try {
      const { data } = await paymentService.getVindiPlans()
      const currentPlanName = planInfos[this.planPriority].name
      const regex = new RegExp(`\\b${currentPlanName}\\b`, 'i')
      const filteredPlans = data.data.filter(
        (plan) => plan.status === 'active' && regex.test(plan.name)
      )
      this.vindiPlans = filteredPlans
    } catch (error) {
      console.error(error)
    } finally {
      this.loadingPlans = false
    }
  },

  computed: {
    disableButton() {
      return !this.selectedPlan?.id
    },
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },
    isSelected(planId) {
      if (this.selectedPlan && this.selectedPlan.id) {
        return this.selectedPlan.id == planId
      }
      return false
    },
    redirectToPayment() {
      this.$router.push({
        path: '/payment',
        query: {
          plan_priority: this.planPriority,
          frequency: this.selectedPlan.interval_name,
        },
      })
      this.closeModal()
    },
    redirectToPlans() {
      this.$router.replace({
        path: '/plans',
      })
      this.closeModal()
    },
    selectPlan(planId) {
      this.selectedPlan = this.vindiPlans.find((plan) => plan.id == planId)
    },
  },
}
</script>

<style lang="scss" scoped>
.light-plan-payment-modal {
  background-color: $color-background-white;
  border-radius: $border-radius-m;
  padding: $spacing-m $spacing-l;

  .modal__header {
    h1 {
      @include heading-medium;
      color: $color-text-secondary;
      text-align: center;
    }
    p {
      @include paragraph-medium;
      color: $color-text-neutral;
      text-align: center;
    }
  }
  .modal__content {
    h2 {
      @include heading-x-small;
      color: $color-text-secondary;
    }
    .no-content {
      @include heading-x-small;
      color: $color-text-neutral;
      padding: $spacing-xl;
      border-radius: $border-radius-m;
      border: 1px solid $color-border;
      text-align: center;
    }
    .modal__cancel {
      @include paragraph-small;
      color: $color-text-neutral;
      text-align: center;
    }
    .modal__terms {
      @include paragraph-x-small;
      color: $color-text-neutral;
      text-align: center;

      a {
        color: $color-text-tertiary;
        text-decoration-line: underline;
      }
    }
  }
}
</style>
