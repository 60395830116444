<template>
  <div class="df-flex-sm df-flex-col align-center without-weather">
    <p class="weather__title">
      {{ $t('weather.info') }}
    </p>
    <div>
      <v-img
        alt="no-weather"
        src="icons/ic-no-weather.svg"
        max-width="100"
        width="100"
        max-height="100"
        height="100"
      />
    </div>
    <div class="df-flex-sm df-flex-col weather__infos">
      <p class="infos__title">
        {{ $t('weather.without_weather') }}
      </p>
      <p class="infos__subtitle">
        {{ $t('weather.wait_to_load') }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WithoutWeather',
}
</script>

<style lang="scss" scoped>
.without-weather {
  background-color: $color-background-white;

  .weather__title {
    @include heading-x-small;
    color: $color-text-disabled;
    text-transform: uppercase;
  }
  .weather__infos {
    padding: 0px $spacing-xxl;

    .infos__title {
      @include heading-x-small;
      text-align: center;
    }
    .infos__subtitle {
      @include paragraph-medium;
      color: $color-text-neutral;
      text-align: center;
    }
  }
}
</style>
