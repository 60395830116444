<template>
  <div class="df-flex-sm df-flex-col physical-market-card">
    <div class="df-flex-m df-flex-col">
      <h4 class="card__date">
        {{ quotation.date.formatDate() }}
      </h4>
      <div class="df-flex-sm align-baseline">
        <div>
          <span class="card__brl-value">
            {{ formatPrice(quotation.price)[0] }}
          </span>
          <span v-if="formatPrice(quotation.price)[1]" class="card__brl-cents">
            ,{{ formatPrice(quotation.price)[1] }}
          </span>
          <span class="card__acronym-bag">/sc</span>
        </div>
        <div>
          <font-awesome-icon
            v-if="quotation.percentage_variation >= 0"
            icon="arrow-up"
            size="xs"
            :color="colorIconSuccess"
          />
          <font-awesome-icon
            v-else
            icon="arrow-down"
            size="xs"
            :color="colorIconDanger"
          />
          <span class="card__percentage-variation">
            {{ porcentageVariation }}%
          </span>
        </div>
      </div>
    </div>
    <div class="df-flex-l df-flex-col">
      <div class="card__city-country">
        {{ quotation.name }}
      </div>
      <div class="df-flex-m">
        <div class="df-col-5 df-col-12-t df-col-12-m">
          <v-select
            v-model="crop"
            hide-details
            item-value="id"
            outlined
            :item-text="(item) => $t(item.messageKey)"
            :items="enabledCrops"
            :label="$t('crop.crop')"
            @change="getQuotations(crop)"
          />
        </div>
        <div class="df-col-7 df-col-12-t df-col-12-m">
          <v-select
            v-model="quotation"
            hide-details
            item-text="name"
            item-value="id"
            outlined
            return-object
            :items="quotationsSelect"
            :label="$t('square')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import colors from '@/assets/styles/__colors.module.scss'
import { mapGetters } from 'vuex'
import { currentLanguage } from '@/language'

export default {
  name: 'PhysicalMarketCard',

  data() {
    return {
      colorIconDanger: colors.colorIconDanger,
      colorIconSuccess: colors.colorIconSuccess,
      crop: 1,
      currentLanguage,
      quotations: [],
      quotation: {
        id: null,
        name: null,
        percentage_variation: 0,
        date: new Date().toLocaleDateString(),
      },
    }
  },

  watch: {
    quotations(val, old) {
      if (val !== old) {
        this.quotation = val[0]
      }
    },
  },

  mounted() {
    this.crop = this.enabledCrops?.length ? this.enabledCrops[0] : null
    this.getQuotations(this.crop.id)
  },

  computed: {
    ...mapGetters('seasonsCrops', ['enabledCrops']),
    quotationsSelect() {
      return [...this.quotations]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((quotation) => ({
          ...quotation,
          name: quotation.name
            .split(' ')
            .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
            .join(' '),
        }))
    },
    porcentageVariation() {
      return this.quotation.percentage_variation
        ? this.quotation.percentage_variation.toFixed(1).replace('-', '')
        : 0
    },
  },

  methods: {
    async getQuotations(cropId) {
      this.$emit('loading-physical-market', true)
      try {
        const { data } = await this.$api.quotations.getAllPhysicalMarket(cropId)
        this.quotations = data
      } catch (error) {
        console.error(error)
      } finally {
        this.$emit('loading-physical-market', false)
      }
    },

    formatPrice(price) {
      const language = this.currentLanguage()
      const validPrice = !isNaN(parseFloat(price))
        ? parseFloat(price).toFixed(2)
        : '0.00'

      if (language === 'pt') {
        return [`R$ ${validPrice.replace('.', ',')}`, '']
      }

      return [`$ ${validPrice.split('.')[0]}`, validPrice.split('.')[1]]
    },
  },
}
</script>

<style lang="scss" scoped>
.physical-market-card {
  padding: $spacing-l;

  .card__date {
    @include label-x-small;
    color: $color-text-neutral;
    font-weight: 600;
    text-transform: uppercase;
  }
  .card__brl-value {
    @include heading-large;
  }
  .card__brl-cents {
    @include label-small;
    font-weight: 600;
    text-transform: uppercase;
  }
  .card__acronym-bag {
    @include label-small;
    color: $color-text-disabled;
  }
  .card__percentage-variation {
    @include label-x-small;
    font-weight: 600;
  }
  .card__city-country {
    @include label-x-small;
    color: $color-text-neutral;
    text-transform: capitalize;
  }
}
</style>
