export default {
  Home: {
    header: {
      hello_user: 'Olá {0}, seja bem vindo(a) à',
      edit_farm: 'Editar fazenda',
      enable_farm: 'Habilitar fazenda',
      fieldview_import: 'Importar do Climate FieldView',
      conditional_import_farms_and_plots:
        'Caso tenha conta, você pode importar fazendas e talhões',
      sown_area: 'Área semeada',
    },
    quick_actions: {
      quick_actions: 'ações rápidas',
      see_catalog: 'ver catálogo',
      plan_fungicide: 'planejar fungicida',
      create_note: 'criar anotação',
      see_fields: 'ver talhões',
      register_sowing: 'registrar semeadura',
    },
    item_list: {
      fields: 'talhões',
      see_all: 'ver todos',
      active_sowings: 'semeaduras ativas',
      sowings: 'semeaduras',
    },
    sowing: {
      next_spray: 'Próxima Aplicação',
      complete: 'completo',
    },
  },
}
