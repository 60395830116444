<template>
  <loader v-if="loading" />
  <div v-else>
    <field-header :field="plot" :planting-area="areaTotal" />
    <div class="df-flex-l field-home">
      <div class="df-col-4 df-col-12-m df-col-12-t home__category">
        <field-infos-card>
          <template #planting>
            <sowing-tab
              v-if="hasPermission($p.planting.list)"
              :plot-id="plotId"
              @areaTotal="setAreaTotal"
            />
            <upsell v-else class="infos-upsell" />
          </template>
          <template #notes>
            <note-tab :plot-id="plotId" />
          </template>
          <template #weather>
            <weather-tab />
          </template>
        </field-infos-card>
      </div>
      <div class="df-col-8 df-col-12-m df-col-12-t home__field-map">
        <ndvi-upsell v-if="!ndviActive" class="map-upsell-ndvi" />
        <gmap :plot="plot" v-if="plot && !plot.ndviEnabled" />
        <div v-if="ndviActive && plot && plot.ndviEnabled" class="map-ndvi">
          <ndvi-navigation
            class="map-ndvi__navigation"
            :images="imagesByDate"
            @date-selected="setTabs"
          />
          <ndvi-map
            :empty-images="emptyImages"
            :tab-items="tabs"
            :longitude="longitude"
            :latitude="latitude"
            :bounds="bounds"
          />
          <ndvi-indice class="map-ndvi__indice" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FieldInfosCard from '@/modules/plot/components/FieldInfosCard.vue'
import FieldHeader from '@/modules/plot/components/FieldHeader.vue'
import Gmap from '@/modules/plot/components/Maps/Gmap.vue'
import Loader from '@/components/Loaders/Loader.vue'
import moment from 'moment'
import NdviNavigation from '@/modules/plot/components/Maps/NdviNavigation.vue'
import NdviIndice from '@/modules/plot/components/Maps/NdviIndice.vue'
import NdviMap from '@/modules/plot/components/Maps/NdviMap.vue'
import NdviUpsell from '@/modules/plot/components/Upsell/NdviUpsell.vue'
import NoteTab from '@/modules/plot/components/NoteTab.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import SowingTab from '@/modules/plot/components/SowingTab.vue'
import Upsell from '@/modules/plot/components/Upsell/Upsell.vue'
import WeatherTab from '@/modules/plot/components/WeatherTab.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FieldHome',

  mixins: [PermissionMixin],

  components: {
    FieldHeader,
    Gmap,
    FieldInfosCard,
    Loader,
    NdviIndice,
    NdviNavigation,
    NdviMap,
    NdviUpsell,
    NoteTab,
    SowingTab,
    Upsell,
    WeatherTab,
  },

  data() {
    return {
      plot: { id: null, nome: '' },
      tabs: [],
      imagesByDate: [],
      loadingDates: false,
      areaTotal: 0,
      loading: false,
    }
  },

  watch: {
    async plotId(val, old) {
      if (val != old && this.ndviActive) {
        await this.fetchImages()
      }
      if (val != old && !this.ndviActive) {
        this.setPlot()
      }
    },
    immediate: true,
  },

  async created() {
    this.loading = true
    try {
      const params = {
        farmId: this.currentFarmId,
        params: 'created_at:desc&enabled=true',
      }
      await this.getPlots(params)
    } finally {
      this.loading = false
    }

    this.setPlot()
    if (this.ndviActive) {
      await this.fetchImages()
    }
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    ...mapGetters('user', ['currentUser']),
    ...mapGetters('plot', ['plots']),
    plotId() {
      return this.$route?.query?.plot_id
    },
    latitude() {
      return parseFloat(this.plot?.latitude)
    },
    longitude() {
      return parseFloat(this.plot?.longitude)
    },
    bounds() {
      let lat = this.plot.coordenadas_lat.split('|')
      let lng = this.plot.coordenadas_lng.split('|')
      let polygon = []

      for (let x = 0; x < lat.length; x++) {
        polygon.push([parseFloat(lat[x]), parseFloat(lng[x])])
      }

      return polygon
    },
    emptyImages() {
      return !this.imagesByDate?.length
    },
    ndviActive() {
      return this.currentUser?.ndvi
    },
  },

  methods: {
    ...mapActions('plot', ['getPlots']),
    setPlot() {
      this.plot = this.plots.find((plot) => plot.id == this.plotId)
    },
    async fetchImages() {
      this.loading = true
      this.loadingDates = true
      this.setPlot()
      const attributes = {
        plot_id: Number(this.plotId),
        dates: [
          // same range as in ModalData method minDate
          moment().subtract(499, 'days').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
      }
      try {
        const { data } = await this.$api.ndvi.searchImages(attributes)
        if (data.length) {
          this.imagesByDate = this.orderByDescDates(data)
          this.setTabs(this.imagesByDate[0].images)
        }
      } catch (e) {
        const { message } = e?.response?.data
        this.$root.$emit('notify', 'error', message)
      } finally {
        this.loadingDates = false
        this.loading = false
      }
    },
    orderByDescDates(data) {
      return data.sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })
    },
    setTabs(tabs) {
      this.tabs = Object.entries(tabs).map(([key, value]) => ({ key, value }))
    },
    setAreaTotal(area) {
      this.areaTotal = area
    },
  },
}
</script>

<style lang="scss" scoped>
.field-home {
  max-width: 1472px;
  margin: 0px auto;
  padding: 0px 16px 40px 16px;
  height: calc(100vh - 176px);

  @include d(t) {
    height: auto;
  }
  @include d(m) {
    padding: 0px 8px 12px 8px;
    height: calc(100vh - 208px);
  }

  .home__category {
    min-width: 300px;

    @include d(t) {
      min-height: 450px;
      height: 450px;
    }
    .infos-upsell {
      margin: 40px auto;

      @include d(t) {
        margin: 12px auto;
      }
    }
  }
  .home__field-map {
    position: relative;

    @include d(t) {
      height: 450px;
    }
    .map-upsell-ndvi {
      position: absolute;
      top: 20px;
      left: 56px;
      width: calc(100% - 56px - 56px);
      z-index: 2;

      @include d(t) {
        left: 20px;
        width: calc(100% - 56px - 20px);
      }
    }
    .map-ndvi {
      height: 100%;

      .map-ndvi__navigation {
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: fit-content;
        max-width: 50%;
        z-index: 2;
      }
      .map-ndvi__indice {
        position: absolute;
        bottom: 20px;
        left: 20px;
        width: fit-content;
        z-index: 2;

        @include d(m) {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
</style>
