import { getCustomDate } from '@/utils/time'

const physicalMarkets = [
  {
    id: 1,
    name: 'tapejara/rs (cotapel)',
    date: getCustomDate(-180),
    price: '124.0000',
    percentage_variation: 1.61,
    crop_id: 1,
  },
  {
    id: 2,
    name: 'brasília/df (coopa-df)',
    date: getCustomDate(-200),
    price: '114.0000',
    percentage_variation: 0,
    crop_id: 1,
  },
  {
    id: 3,
    name: 'luís eduardo magalhães/ba',
    date: getCustomDate(-220),
    price: '110.0000',
    percentage_variation: 0,
    crop_id: 1,
  },
  {
    id: 4,
    name: 'pato branco/pr (coopertradição)',
    date: getCustomDate(-5),
    price: '122.5000',
    percentage_variation: 1.63,
    crop_id: 2,
  },
  {
    id: 5,
    name: 'palma sola/sc (coopertradição)',
    date: getCustomDate(-10),
    price: '123.5000',
    percentage_variation: 1.62,
    crop_id: 2,
  },
  {
    id: 6,
    name: 'rio do sul/sc (cravil)',
    date: getCustomDate(-7),
    price: '125.0000',
    percentage_variation: 0,
    crop_id: 2,
  },
]

const marketIndexes = [
  {
    id: 1,
    name: 'Soybean Futures',
    open: '9.9375',
    close: '9.9625',
    date: '2025-01-06 00:00:00',
    contract_type: 'Bushels',
    percentage_variation: -0.45,
  },
  {
    id: 2,
    name: 'Soybean Meal Futures',
    open: '3.1',
    close: '3.068',
    date: '2025-01-06 00:00:00',
    contract_type: 'Tonnes',
    percentage_variation: 0.39,
  },
  {
    id: 3,
    name: 'Soybean Oil Futures',
    open: '0.3999',
    close: '0.4025',
    date: '2025-01-06 00:00:00',
    contract_type: 'Lbs',
    percentage_variation: -0.84,
  },
  {
    id: 4,
    name: 'Chicago SRW Wheat Futures',
    open: '5.3025',
    close: '5.4',
    date: '2025-01-06 00:00:00',
    contract_type: 'Bushels',
    percentage_variation: -2.13,
  },
  {
    id: 5,
    name: 'Corn Futures',
    open: '4.515',
    close: '4.5675',
    date: '2025-01-06 00:00:00',
    contract_type: 'Bushels',
    percentage_variation: -1.26,
  },
]

export { marketIndexes, physicalMarkets }
