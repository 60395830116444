<template>
  <div class="sowing-tab">
    <div v-if="isLoading" class="df-flex-xl df-flex-col">
      <v-skeleton-loader
        v-for="(_, index) in sowings.length || 3"
        :key="index"
        height="126px"
        type="image"
        width="auto"
      />
    </div>
    <div v-else-if="hasPlanting" class="df-flex-xl df-flex-col">
      <div v-for="sowing in sowings" :key="sowing.id">
        <div class="df-flex-m df-flex-col sowing-infos">
          <div class="df-flex-none align-center">
            <div class="df-col-6 df-flex-xm df-flex-col info__title">
              <div class="df-flex-xm align-center">
                <v-img
                  class="crop-image"
                  max-width="12px"
                  :alt="getCropIcon(sowing.crop_id).alt"
                  :src="getCropIcon(sowing.crop_id).src"
                />
                <span>
                  {{ $t(`Plot.Crop.${getCropName(sowing.crop_id)}`) }}
                </span>
              </div>
              <p>{{ sowing.cultivar }}</p>
            </div>
            <div
              v-if="sowing.data_ideal_proxima_aplicacao"
              class="df-col-6 df-flex-xm df-flex-col info-date"
            >
              <div class="df-flex-sm align-center info__title">
                <font-awesome-icon icon="calendar" color="#aab2a9" />
                <span>
                  {{ $t('Plot.Planting.will_harvest_in') }}
                </span>
              </div>
              <p class="sown-data">
                {{
                  sowing.data_ideal_proxima_aplicacao
                    ? sowing.data_ideal_proxima_aplicacao.formatDate()
                    : $t('Plot.Planting.no_prediction')
                }}
              </p>
            </div>
          </div>
          <div class="df-flex-none align-center infos">
            <div class="df-col-6 df-flex-xm df-flex-col info-area">
              <div class="df-flex-sm align-center info__title">
                <font-awesome-icon icon="vector-square" color="#aab2a9" />
                <span>{{ $t('Plot.Planting.sown_area') }}</span>
              </div>
              <p class="sown-data">
                {{ area(sowing.area) }}
                {{ $unitMeasures['area'][$currentLanguage()] }}
              </p>
            </div>
            <div class="df-col-6 df-flex-xm df-flex-col info-date">
              <div class="df-flex-sm align-center info__title">
                <font-awesome-icon icon="calendar" color="#aab2a9" />
                <span>
                  {{ $t('Plot.Planting.sown_in') }}
                </span>
              </div>
              <p class="sown-data">
                {{ sowing.data_semeadura.formatDate() }}
              </p>
            </div>
          </div>
          <router-link
            :to="'/execucao?semeadura_id=' + sowing.id"
            class="df-flex-xm align-center"
            @click="registerEvent"
          >
            <span class="view-more">
              {{ $t('Plot.Planting.view_more') }}
            </span>
            <font-awesome-icon color="#c3d1e9" icon="arrow-right" size="xs" />
          </router-link>
        </div>
      </div>
    </div>
    <div v-else class="warning-title">
      {{ $t('Plot.Warnings.planting') }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { convertToTargetUnit } from '@/components/Form/converter'
import { logEvent, events } from '@/services/analytics'
import { getCropNameById, getCropIconById } from '@/utils/crops.js'

export default {
  name: 'SowingTab',

  props: {
    plotId: {
      type: String,
      default: null,
    },
  },

  async created() {
    await this.loadSowingsFilter()
    this.sumSownArea()
  },

  watch: {
    plotId(old, val) {
      if (old != val) {
        this.sumSownArea()
      }
    },
  },

  computed: {
    ...mapGetters('semeaduras', ['semeaduras', 'isLoading']),
    hasPlanting() {
      return this.sowings.length > 0
    },
    sowings() {
      return this.semeaduras.filter(
        (semeadura) => semeadura.talhao_id == this.plotId
      )
    },
  },

  methods: {
    ...mapActions('semeaduras', ['loadSowingsFilter']),
    area(area) {
      return convertToTargetUnit(area, 'area')
    },
    sumSownArea() {
      const areaTotal = this.sowings.reduce(
        (total, semeadura) => total + parseFloat(semeadura.area),
        0
      )
      const fixedAreaTotal = Number.isInteger(areaTotal)
        ? areaTotal
        : parseFloat(areaTotal.toFixed(2))
      this.$emit('areaTotal', fixedAreaTotal)

      return fixedAreaTotal
    },
    getCropName(cropId) {
      return getCropNameById(cropId)
    },
    getCropIcon(cropId) {
      return getCropIconById(cropId)
    },
    registerEvent() {
      logEvent(events.plotModule.visitSeedlingPage)
    },
  },
}
</script>

<style lang="scss" scoped>
.sowing-tab {
  min-height: 100%;

  .sowing-infos {
    .info__title {
      font-family: 'Rubik';
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      text-transform: uppercase;
      color: #aab2a9;
      p {
        font-family: 'Rubik';
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        text-transform: uppercase;
        color: #0d0d0d;
      }
    }
    .infos {
      .info-area {
        .sown-data {
          font-family: 'Rubik';
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          color: #5b6459;
        }
      }
      .info-date {
        .sown-data {
          font-family: 'Rubik';
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          color: #5b6459;
        }
      }
    }
    .view-more {
      font-family: 'Rubik';
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      color: #39af49;
    }
  }
  .warning-title {
    font-family: 'Rubik';
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #1a2b46;
  }
}
</style>
