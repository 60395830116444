<template>
  <div class="df-flex-m df-flex-col card-weather-condition">
    <div class="df-flex-xl">
      <div class="df-flex-none flex-grow-1">
        <div class="df-flex-none align-center justify-center">
          <img
            loading="lazy"
            max-height="40px"
            max-width="40px"
            :alt="dayCondition(todaysWeather).alt"
            :src="dayCondition(todaysWeather).src"
          />
        </div>
        <div class="df-flex-xs df-flex-col">
          <div>
            <span class="weather__highest-temperature">
              {{ currentTemperature }}
            </span>
            <span class="weather__highest-unit">
              {{ $unitMeasures['temperature'][$currentLanguage()] }}
            </span>
          </div>
          <div class="df-flex-sm align-center">
            <div class="df-flex-xs align-center flex-grow-0">
              <font-awesome-icon
                icon="arrow-down"
                size="xs"
                :color="colorIconTertiary"
              />
              <span class="weather__lowest-temperature">
                {{ todayTempMin }}
              </span>
              <span class="weather__lowest-unit">
                {{ $unitMeasures['temperature'][$currentLanguage()] }}
              </span>
            </div>
            <div class="df-flex-xs align-center flex-grow-0">
              <font-awesome-icon
                icon="arrow-up"
                size="xs"
                :color="colorIconDanger"
              />
              <span class="weather__lowest-temperature">
                {{ todayTempMax }}
              </span>
              <span class="weather__lowest-unit">
                {{ $unitMeasures['temperature'][$currentLanguage()] }}
              </span>
            </div>
          </div>
          <div class="weather__precipitation">
            {{ todayMmDay }}
            {{ $unitMeasures['rainfall'][$currentLanguage()] }}
          </div>
        </div>
      </div>
      <div
        class="df-flex-none df-flex-col flex-grow-1 weather__forecasting-factors"
      >
        <div class="df-flex-none align-center justify-space-between">
          {{ $t('Weather.wind') }}
          <span class="forecasting__climate-units">
            {{ windSpeed }}
            {{ $unitMeasures['speed'][$currentLanguage()] }}
          </span>
        </div>
        <div
          v-if="soilMoisture"
          class="df-flex-none align-center justify-space-between"
        >
          {{ $t('Weather.humidity') }}
          <span class="forecasting__climate-units">{{ soilMoisture }}%</span>
        </div>
        <div class="df-flex-none align-center justify-space-between">
          {{ $t('Weather.moisture') }}
          <span class="df-flex-xs justify-end forecasting__climate-units">
            {{ dewPointTemperature }}
            {{ $unitMeasures['temperature'][$currentLanguage()] }}
          </span>
        </div>
      </div>
    </div>
    <hr />
    <div class="df-flex-sm weather__week-forecast">
      <div
        v-for="(day, index) in nextDaysData"
        :key="index"
        class="df-flex-xs df-flex-col align-center justify-center"
      >
        <div class="df-flex-none df-flex-col">
          <p class="forecast__day-of-week">{{ dayWeek(day.date) }}</p>
          <p class="forecast__day-month">{{ dayMonth(day.date) }}</p>
        </div>
        <img
          loading="lazy"
          max-width="30px"
          :alt="dayCondition(day).alt"
          :src="dayCondition(day).src"
        />
        <div class="forecast__rain">
          {{ dayPrecMm(day) }}
          {{ $unitMeasures['rainfall'][$currentLanguage()] }}
        </div>
        <div class="df-flex-none df-flex-col">
          <p class="forecast__maximum-temperature">
            {{ dayTempMax(day) }}
            {{ $unitMeasures['temperature'][$currentLanguage()] }}
          </p>
          <p class="forecast__minimum-temperature">
            {{ dayTempMin(day) }}
            {{ $unitMeasures['temperature'][$currentLanguage()] }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import colors from '@/assets/styles/__colors.module.scss'
import { convertToTargetUnit } from '@/components/Form/converter'

export default {
  name: 'CardWeatherCondition',

  props: {
    weather: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      colorIconDanger: colors.colorIconDanger,
      colorIconTertiary: colors.colorIconTertiary,
    }
  },

  computed: {
    isThereTodaysWeather() {
      return this.weather.length
    },
    todaysWeather() {
      if (this.isThereTodaysWeather) {
        return this.weather[0]
      }
      return []
    },
    nextDaysData() {
      return this.weather.slice(1)
    },
    windSpeed() {
      return convertToTargetUnit(
        this.isThereTodaysWeather ? this.todaysWeather.wind_speed : 0,
        'speed'
      )
    },
    dewPointTemperature() {
      return convertToTargetUnit(
        this.isThereTodaysWeather
          ? this.todaysWeather.dew_point_temperature
          : 0,
        'temperature'
      )
    },
    soilMoisture() {
      return this.isThereTodaysWeather ? this.todaysWeather.soil_moisture : 0
    },
    todayTempMax() {
      return convertToTargetUnit(
        this.isThereTodaysWeather ? this.todaysWeather.temperature?.max : 0,
        'temperature'
      )
    },
    todayTempMin() {
      return convertToTargetUnit(
        this.isThereTodaysWeather ? this.todaysWeather.temperature?.min : 0,
        'temperature'
      )
    },
    todayMmDay() {
      return convertToTargetUnit(
        this.isThereTodaysWeather ? this.todaysWeather.hourly?.amount : 0,
        'rainfall'
      )
    },
    currentTemperature() {
      return convertToTargetUnit(
        this.isThereTodaysWeather ? this.todaysWeather.temperature?.current : 0,
        'temperature'
      )
    },
  },

  methods: {
    dayTempMax(day) {
      return convertToTargetUnit(day.temperature.max, 'temperature')
    },
    dayTempMin(day) {
      return convertToTargetUnit(day.temperature.min, 'temperature')
    },
    dayPrecMm(day) {
      return convertToTargetUnit(day.hourly.amount, 'rainfall')
    },
    dayMonth(date) {
      return this.moment(date).format('DD')
    },
    dayWeek(date) {
      const day = this.moment(date)
        .locale(this.$currentLanguage())
        .format('ddd')

      const today = this.moment().locale(this.$currentLanguage()).format('ddd')

      let label = this.$t('dias_semana.hoje')
      label = label[0].toUpperCase() + label.slice(1)

      return day === today ? label : day
    },
    dayCondition(item) {
      const mm = this.weather?.length ? item.hourly.amount : 0
      if (mm < 3)
        return { alt: 'sun', src: require('@/assets/icons/ic-sun.svg') }
      else if (mm > 3 && mm < 5)
        return {
          alt: 'cloud',
          src: require('@/assets/icons/ic-cloud.svg'),
        }
      else
        return {
          alt: 'rain',
          src: require('@/assets/icons/ic-rain.svg'),
        }
    },
  },
}
</script>

<style lang="scss" scoped>
.card-weather-condition {
  hr {
    border: 0;
    height: 1px;
    opacity: 0.2;
    border-bottom: 1px dashed $black;
  }
  .weather__highest-temperature {
    @include heading-x-large;
    color: $color-text-black;
    text-transform: uppercase;
  }
  .weather__highest-unit {
    @include label-large;
    color: $color-text-disabled;
  }
  .weather__lowest-temperature {
    @include paragraph-x-small;
    font-weight: 600;
    text-transform: uppercase;
  }
  .weather__lowest-unit {
    @include label-x-small;
    color: $color-text-disabled;
  }
  .weather__precipitation {
    @include heading-x-small;
    color: $color-text-tertiary;
    font-size: 12px;
    text-transform: uppercase;
  }
  .weather__forecasting-factors {
    @include paragraph-x-small;
    color: $color-text-disabled;
    font-weight: 600;
    text-transform: uppercase;

    .forecasting__climate-units {
      @include paragraph-x-small;
    }
  }
  .weather__week-forecast {
    .forecast__day-of-week {
      @include paragraph-x-small;
      color: $color-text-disabled;
      text-align: center;
    }
    .forecast__day-month {
      @include paragraph-x-small;
      color: $color-text-neutral;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
    }
    .forecast__rain {
      @include paragraph-small;
      color: $color-text-secondary;
      text-align: center;
    }
    .forecast__maximum-temperature {
      @include paragraph-x-small;
      color: $color-text-disabled;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
    }
    .forecast__minimum-temperature {
      @include paragraph-x-small;
      color: $color-text-neutral;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
    }
  }
}
</style>
