<template>
  <v-dialog height="390px" persistent width="470px" :value="true">
    <div class="df-flex-l df-flex-col align-center modal__title-modal">
      <button class="modal__close-button" @click="closeModal">
        <font-awesome-icon icon="times" size="sm" :color="colorIconDisabled" />
      </button>
      <v-img src="icons/ic-farmer-alert.svg" width="116px" height="128px" />
      <h1 class="modal__title">
        {{ $t('freemium.dialog.title') }}
      </h1>
      <span
        class="modal__text"
        v-html="$t('freemium.dialog.description')"
      ></span>
      <v-card-actions>
        <df-button @click="openPaymentChoicesModal">
          {{ $t('freemium.button.title') }}
        </df-button>
      </v-card-actions>
    </div>
    <plan-payment-choices-modal
      v-if="isPaymentModalOpened"
      :plan-priority="planLevels.light"
      @close="closePaymentChoicesModal"
    />
  </v-dialog>
</template>

<script>
import colors from '@/assets/styles/__colors.module.scss'
import DfButton from '@/lib/components/Button/DfButton.vue'
import planLevels from '@/modules/plans/data/planLevels'
import PlanMixin from '@/components/PlanMixin'
import { mapGetters } from 'vuex'
const PlanPaymentChoicesModal = () =>
  import('@/modules/payment/components/modal/PlanPaymentChoicesModal.vue')

export default {
  name: 'LimitExceededModal',

  mixins: [PlanMixin],

  components: {
    DfButton,
    PlanPaymentChoicesModal,
  },

  data() {
    return {
      colorIconDisabled: colors.colorIconDisabled,
      isPaymentModalOpened: false,
      planLevels,
    }
  },

  computed: {
    ...mapGetters('user', ['isBrazil']),
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },
    closePaymentChoicesModal() {
      this.isPaymentModalOpened = false
    },
    openPaymentChoicesModal() {
      if (this.isBrazil) {
        this.isPaymentModalOpened = true
      } else {
        this.closeModal()
        this.$router.replace({
          path: '/plans',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal__title-modal {
  background-color: $color-background-white;
  border-radius: $border-radius-m;
  padding: $spacing-l;
  position: relative;

  .modal__close-button {
    position: absolute;
    top: $spacing-l;
    right: $spacing-xl;
  }
  .modal__title {
    @include heading-x-small;
    color: $color-text-secondary;
    text-align: center;
  }
  .modal__text {
    @include paragraph-medium;
    color: $color-text-neutral;
    text-align: center;
  }
}
</style>
