export default {
  Home: {
    header: {
      hello_user: 'Hola {0} bienvenido a la hacienda',
      edit_farm: 'Editar Hacienda',
      enable_farm: 'Habilitar hacienda',
      fieldview_import: 'Importar del Clima FieldView',
      conditional_import_farms_and_plots:
        'Si tiene una cuenta, tu puede importar haciendas y lotes',
      sown_area: 'Area sembrada',
    },
    quick_actions: {
      quick_actions: 'acciones rapidas',
      see_catalog: 'ver catalogo',
      plan_fungicide: 'plan fungicida',
      create_note: 'crear nota',
      see_fields: 'ver lotes',
      register_sowing: 'registrar siembra',
    },
    item_list: {
      fields: 'lotes',
      see_all: 'ver todo',
      active_sowings: 'siembras activas',
      sowings: 'siembras',
    },
    sowing: {
      next_spray: 'Próxima Aplicación',
      complete: 'completo',
    },
  },
}
