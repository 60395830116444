<template>
  <div class="catalog-home">
    <permission-failed-message v-if="!hasPermission($p.catalog.list)" />
    <div v-else>
      <df-page-header
        :title="$t('Catalog.Home.title')"
        :subtitle="$t('Catalog.Home.subtitle')"
      />
      <div class="home__content">
        <div class="df-flex-m df-flex-col">
          <category-tabs :categories="categories" />
          <crop-selector />
          <v-spacer v-if="showSpacer" class="content__spacer" />
          <div class="df-flex-l">
            <div class="df-col-7 df-col-12-m df-col-12-t">
              <catalog-filter :crop-id="currentCropId" />
              <span class="content__filters">
                {{ $t('results') }}: {{ itemsLoaded }} {{ $t('of') }}
                {{ catalogItemsCount }}
              </span>
              <df-list ref="catalogList" class="content__list-items">
                <df-list-item
                  v-for="(item, index) in catalogItems"
                  :key="index"
                  @click="selectItem(index)"
                >
                  {{ getItem(item) }}
                  <template #right-content>
                    <df-info
                      v-if="!isVariety"
                      icon="list"
                      :description="
                        $t(`Catalog.Options.${item.agronomic_category}`)
                      "
                    />
                    <df-info
                      v-if="isVariety && isSoybean"
                      icon="chart-line"
                      :description="getGmr(item)"
                    />
                    <df-info icon="building" :description="item.asset_owner" />
                  </template>
                </df-list-item>
                <df-list-item
                  v-if="
                    loadingScrolledData ||
                    loadingFilterData ||
                    loadingFilterOptions
                  "
                  class="justify-center"
                >
                  <loader :show-loading-text="false" />
                </df-list-item>
              </df-list>
            </div>
            <v-dialog
              v-if="(isDSMobile || isDSTablet) && mobileCatalogInfoDialog"
              fullscreen
              :value="mobileCatalogInfoDialog"
            >
              <v-card>
                <v-card-title>
                  <button @click="closeMobile">
                    <font-awesome-icon
                      icon="times"
                      size="xs"
                      :color="colorIconDisabled"
                    />
                  </button>
                </v-card-title>
                <v-card-text>
                  <catalog-info
                    v-if="catalogSelectedItem"
                    :item="catalogSelectedItem"
                  />
                </v-card-text>
              </v-card>
            </v-dialog>
            <div class="df-col-5 variety__info">
              <catalog-info
                v-if="catalogSelectedItem"
                :item="catalogSelectedItem"
              />
              <div
                v-else
                class="d-flex flex-column justify-center align-center variety__no-info"
              >
                <v-img
                  alt="warning"
                  max-height="90"
                  src="icons/ic-warning-green.svg"
                  width="90"
                />
                <p class="pt-6">{{ $t('Catalog.no_selected') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <limit-exceeded-modal v-if="isOpenInfoPlan" @close="closeLimitModal" />
  </div>
</template>

<script>
import CatalogFilter from '@/modules/catalog/components/Filter/Filter.vue'
import CatalogInfo from '@/modules/catalog/components/Info/CatalogInfo.vue'
import CategoryTabs from '@/modules/catalog/components/CategoryTabs/CategoryTabs.vue'
import colors from '@/assets/styles/__colors.module.scss'
import CropSelector from '@/modules/catalog/components/CropSelector/CropSelector.vue'
import DfPageHeader from '@/lib/components/Header/DfPageHeader.vue'
import DfInfo from '@/lib/components/Info/DfInfo.vue'
import DfList from '@/lib/components/List/DfList.vue'
import DfListItem from '@/lib/components/List/DfListItem.vue'
import LayoutMixin from '@/components/LayoutMixin.vue'
import Loader from '@/components/Loaders/Loader.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import { mapActions, mapGetters } from 'vuex'
import { logEvent, events } from '@/services/analytics'
const LimitExceededModal = () =>
  import('@/components/Dialogs/LimitExceededModal.vue')

export default {
  name: 'CatalogHome',

  mixins: [LayoutMixin, PermissionMixin],

  components: {
    CatalogFilter,
    CatalogInfo,
    CategoryTabs,
    CropSelector,
    DfInfo,
    DfList,
    DfListItem,
    DfPageHeader,
    LimitExceededModal,
    Loader,
  },

  data() {
    return {
      bottom: false,
      colorIconDisabled: colors.colorIconDisabled,
      categories: [
        {
          id: 1,
          icon: 'vial',
          label: 'Catalog.Categories.fungicide',
          name: 'fungicide',
        },
        {
          id: 2,
          icon: 'seedling',
          label: 'Catalog.Categories.variety',
          name: 'variety',
        },
        {
          id: 3,
          icon: 'bacterium',
          name: 'nematicide',
          label: 'Catalog.Categories.nematicide',
        },
      ],
      currentPage: 1,
      itemsPerPage: 50,
      isOpenInfoPlan: false,
      loadingScrolledData: false,
      mobileCatalogInfoDialog: false,
    }
  },

  mounted() {
    logEvent(events.catalogModule.visitedPage)
    this.$refs.catalogList.$el.addEventListener('scroll', this.handleScroll)
  },

  watch: {
    async bottom(bottom) {
      if (bottom) {
        await this.loadMore()
      }
    },
  },

  computed: {
    ...mapGetters('crops', ['isSoybean', 'currentCropId']),
    ...mapGetters('catalog', [
      'catalogSelectedItem',
      'catalogCategory',
      'loadingFilterData',
      'loadingFilterOptions',
      'catalogItems',
      'catalogItemsCount',
    ]),
    ...mapGetters('farms', ['farms']),
    ...mapGetters('freemium', ['progressOfUse']),
    ...mapGetters('user', ['isFreemiumPlan']),
    isVariety() {
      return this.catalogCategory === 'variety'
    },
    itemsLoaded() {
      return this.catalogItems?.length ?? 0
    },
    showSpacer() {
      return this.catalogCategory === 'nematicide'
    },
  },

  methods: {
    ...mapActions('catalog', ['setCatalogSelectedItem', 'fetchCatalogList']),
    ...mapActions('freemium', ['consumeFreemiumLimit']),
    closeLimitModal() {
      this.isOpenInfoPlan = false
    },
    selectItem(index) {
      if (this.isFreemiumPlan) {
        if (this.progressOfUse < 100) {
          this.consumeFreemiumLimit()

          if (index >= 0 && index < this.catalogItems.length) {
            this.setCatalogSelectedItem(this.catalogItems[index])
          } else {
            this.setCatalogSelectedItem(null)
          }
          this.mobileCatalogInfoDialog = true
        } else {
          this.isOpenInfoPlan = true
        }
      } else {
        if (index >= 0 && index < this.catalogItems.length) {
          this.setCatalogSelectedItem(this.catalogItems[index])
        } else {
          this.setCatalogSelectedItem(null)
        }
        this.mobileCatalogInfoDialog = true
      }
    },
    closeMobile() {
      this.mobileCatalogInfoDialog = false
    },
    getItem(item) {
      if (
        this.catalogCategory === 'fungicide' ||
        this.catalogCategory === 'nematicide'
      ) {
        return item.pesticide
      } else {
        return item.name
      }
    },
    getGmr(item) {
      return `${this.$t('Catalog.gmr')}: ${
        item.general_info?.relative_maturity || this.$t('desconhecido')
      }`
    },
    async loadMore() {
      const nextPage = Math.round(
        this.catalogItems.length / this.itemsPerPage + 1
      )
      if (
        this.catalogItems.length == this.catalogItemsCount ||
        nextPage == this.currentPage
      ) {
        return
      }
      this.currentPage = nextPage
      this.loadingScrolledData = true
      await this.fetchCatalogList(this.currentPage)
      this.loadingScrolledData = false
    },
    handleScroll() {
      const el = this.$refs.catalogList.$el
      const scrollPosition = el.scrollTop
      const listViewport = el.clientHeight
      const scrollDynamicHeight = el.scrollHeight * 0.6
      const bottomOfComponent = scrollPosition >= scrollDynamicHeight
      this.bottom = bottomOfComponent || scrollDynamicHeight < listViewport
    },
  },

  beforeDestroy() {
    this.$refs.catalogList?.$el.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style lang="scss" scoped>
.catalog-home {
  .home__content {
    max-width: 1472px;
    margin: 0px auto;
    padding: 0px $spacing-l $spacing-xxl $spacing-l;

    @include d(m) {
      padding: 0px $spacing-sm $spacing-m $spacing-sm;
    }
    .content__spacer {
      height: 52px;
    }
    .content__filters {
      @include label-x-small;
      color: $color-text-neutral;
      text-transform: uppercase;
      word-wrap: break-word;
    }
    .content__list-items {
      max-height: calc(100vh - 180px);

      @include d(m) {
        max-height: calc(100vh - 150px);
      }
    }
    .variety__info {
      overflow-y: auto;
      max-height: calc(100vh - 100px);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: $spacing-l $spacing-xl;
      border: 1px solid $color-border;
      border-radius: $border-radius-m;

      @include d(t) {
        display: none;
      }
      @include d(m) {
        display: none;
      }
      .variety__no-info {
        @include label-medium;
        color: $color-text-secondary;
      }
    }
  }
}
::v-deep .v-input--radio-group {
  margin: 0px;
  padding: 0px;

  & .v-input__slot {
    margin: 0px;
  }
  & .v-messages {
    display: none;
  }
}
</style>
