<template v-bind="$attrs" v-on="$listeners">
  <v-btn
    :class="classes"
    :depressed="depressed"
    :disabled="disabled"
    :loading="loading"
    :outlined="outlined"
    :plain="plain"
    :text="
      variant === 'text' || variant === 'text-green' || variant === 'text-blue'
    "
    :min-width="minWidth"
    :width="width"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <font-awesome-icon
      v-if="leftIcon"
      :class="[flank, 'mr-2', isIconDisabled]"
      :icon="leftIcon"
      :style="{ color: colorIcon }"
    />
    <p v-if="show" :style="{ width: widthSlot }" class="text-personalized">
      <slot></slot>
    </p>
    <font-awesome-icon
      v-if="icon"
      :class="[flank, 'ml-2', isIconDisabled]"
      :disabled="disabled"
      :icon="icon"
      :style="{ color: colorIcon }"
    />
  </v-btn>
</template>

<script>
export default {
  name: 'DfButton',

  props: {
    colorIcon: {
      default: '',
      type: String,
    },
    depressed: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    flank: {
      default: '',
      type: String,
    },
    icon: {
      default: '',
      type: String,
    },
    leftIcon: {
      default: '',
      type: String,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    minWidth: {
      default: '64px',
      type: String,
    },
    outlined: {
      default: false,
      type: Boolean,
    },
    plain: {
      default: false,
      type: Boolean,
    },
    show: {
      default: true,
      type: Boolean,
    },
    variant: {
      default: 'primary',
      type: String,
      validator: (value) =>
        [
          'primary',
          'secondary',
          'blurred',
          'text',
          'text-secondary',
          'text-green',
          'text-blue',
        ].includes(value),
    },
    width: {
      default: '',
      type: String,
    },
    widthSlot: {
      default: '',
      type: String,
    },
  },

  computed: {
    classes() {
      return {
        'df-button': true,
        'df-button--primary': this.variant === 'primary',
        'df-button--secondary': this.variant === 'secondary',
        'df-button--blurred': this.variant === 'blurred',
        'df-button--text': this.variant === 'text',
        'df-button--text-secondary': this.variant === 'text-secondary',
        'df-button--text-green': this.variant === 'text-green',
        'df-button--text-blue': this.variant === 'text-blue',
      }
    },
    isIconDisabled() {
      return this.disabled ? 'icon--disabled' : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.df-button {
  @include label-small;
  border-radius: $border-radius-sm;
  padding: $spacing-m !important;
  color: $color-text-white !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-weight: 700;
  text-transform: none !important;
  box-shadow: none;
  letter-spacing: 0;
}
.df-button--primary {
  background-color: $color-background-primary !important;
}
.df-button--primary:hover {
  background-color: $color-background-primary-dark !important;
  box-shadow: none !important;
}
.df-button--secondary {
  background-color: $color-background-white !important;
  border: 1px solid $color-border;
  color: $color-text-neutral !important;
}
.df-button--secondary:hover {
  box-shadow: none !important;
}
.df-button--blurred {
  background-color: $color-background-neutral !important;
  box-shadow: none;
}
.df-button--blurred:hover {
  background-color: $color-background-primary !important;
}
.df-button--text {
  color: $color-text-secondary !important;
  box-shadow: none !important;
}
.df-button--text:hover {
  color: $color-text-tertiary !important;
}
.df-button--text-secondary {
  background-color: $color-background-white !important;
  box-shadow: none;
  color: $color-text-secondary !important;
}
.df-button--text-green {
  background-color: transparent !important;
  box-shadow: none;
  color: $color-text-primary !important;
}
.df-button--text-blue {
  background-color: transparent;
  box-shadow: none;
  color: $color-text-secondary !important;
}
.df-button--text-secondary:hover {
  background-color: $color-background-white !important;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3) !important;
}
.text-personalized {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-btn--outlined {
  border: 1px solid $color-border;
}
::v-deep .icon--disabled {
  color: $color-icon-disabled !important;
}
</style>

<style lang="scss">
.df-button {
  &.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(
      :hover
    )
    .v-btn__content {
    opacity: 1;
  }
}
</style>
