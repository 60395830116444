<template>
  <selector-dropdown
    :object-actual="currentFarm"
    :objects="farmTrasform"
    :text-button="$t('farm.see_all_farms')"
    :title="$t('farm.select_farm')"
    :tooltip-one="$t('sowing.active_sowing')"
    :tooltip-two="$t('application.closets_applications')"
    icon-one="seedling"
    icon-two="spray-can"
    icon-button="bars"
    :width-slot="isUSA ? '120px' : '150px'"
    @clickButton="changeFarm"
    @actionButton="redirectToFarms"
  />
</template>

<script>
import SelectorDropdown from '@/components/Selectors/SelectorDropdown.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FarmSelector',

  components: {
    SelectorDropdown,
  },

  computed: {
    ...mapGetters('farms', ['currentFarm', 'farms']),
    ...mapGetters('user', ['isUSA']),
    farmTrasform() {
      return this.onlyFarmEnable.map((farm) => {
        return {
          ...farm,
          attribute_one: farm.active_sowing,
          attribute_two: farm.closets_applications,
        }
      })
    },
    onlyFarmEnable() {
      return this.farms.filter((farm) => {
        if (farm.enabled) {
          return farm
        }
      })
    },
  },

  methods: {
    ...mapActions('farms', ['setFarm']),
    changeFarm(farm) {
      this.setFarm(farm?.id)

      this.$router.replace({
        query: { fazenda_id: farm?.id },
      })

      window.location.reload()
    },
    redirectToFarms() {
      this.$router.push({
        path: '/farms',
      })
    },
  },
}
</script>
