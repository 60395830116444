import authModuleTranslation from '@/modules/auth/translation'
import catalogModuleTranslations from '@/modules/catalog/translation'
import comparisonTranslations from '@/modules/comparison/translation'
import homeTranslations from '@/modules/home/translation'
import menuModuleTranslations from '@/modules/menu/translation'
import notesModuleTranslations from '@/modules/notes/translation'
import performanceTranslations from '@/modules/performance/translation'
import plansModuleTranslations from '@/modules/plans/translation'
import plotModuleTranslations from '@/modules/plot/translation'
import reportTranslations from '@/modules/reports/translation'
import bannersTranslations from '@/components/Banners/translation'
import componentsTranslations from '@/lib/components/translation'
import modalTranslations from '@/lib/Modal/translation'
import weatherTranslations from '@/components/Weather/translation'

export default {
  // modules
  ...authModuleTranslation.es,
  ...catalogModuleTranslations.es,
  ...comparisonTranslations.es,
  ...homeTranslations.es,
  ...menuModuleTranslations.es,
  ...notesModuleTranslations.es,
  ...performanceTranslations.es,
  ...plansModuleTranslations.es,
  ...plotModuleTranslations.es,
  ...reportTranslations.es,
  // modules
  ...bannersTranslations.es,
  ...componentsTranslations.es,
  ...modalTranslations.es,
  ...weatherTranslations.es,

  $vuetify: {
    badge: 'Distintivo',
    close: 'Fechar',
    loading: 'Cargando',
    dataIterator: {
      noResultsText: 'Nenhum dado encontrado',
      loadingText: 'Carregando itens...',
    },
    dataTable: {
      itemsPerPageText: 'Linhas por página:',
      ariaLabel: {
        sortDescending: 'Ordenado decrescente.',
        sortAscending: 'Ordenado crescente.',
        sortNone: 'Não ordenado.',
        activateNone: 'Ative para remover a ordenação.',
        activateDescending: 'Ative para ordenar decrescente.',
        activateAscending: 'Ative para ordenar crescente.',
      },
      sortBy: 'Ordenar por',
    },
    dataFooter: {
      itemsPerPageText: 'Itens por página:',
      itemsPerPageAll: 'Todos',
      nextPage: 'Próxima página',
      prevPage: 'Página anterior',
      firstPage: 'Primeira página',
      lastPage: 'Última página',
      pageText: '{0}-{1} de {2}',
    },
    datePicker: {
      itemsSelected: '{0} selecionado(s)',
      prevMonthAriaLabel: 'mes anterior',
      nextMonthAriaLabel: 'próximo mes',
    },
    noDataText: 'Não há dados disponíveis',
    carousel: {
      prev: 'Visão anterior',
      next: 'Próxima visão',
      ariaLabel: {
        delimiter: 'Slide {0} de {1} do carrossel',
      },
    },
    calendar: {
      moreEvents: 'Mais {0}',
    },
    fileInput: {
      counter: '{0} arquivo(s)',
      counterSize: '{0} arquivo(s) ({1} no total)',
    },
    timePicker: {
      am: 'AM',
      pm: 'PM',
    },
  },

  // Digifarmz
  language: {
    pt: 'Português',
    es: 'Español',
    en: 'English',
  },
  yes: 'Si',
  no: 'No',
  or: 'O',
  close: 'Cerrar',
  password_recovery: {
    temporary_confirmation:
      '¿Ha solicitado una solicitud de recuperación de contraseña? La contraseña temporal caduca en 1 día. Haga clic en "sí" para actualizar su contraseña.',
  },
  configurations: 'Configuraciones',
  loading: 'Cargando...',
  filter: 'Filter',
  category: 'Categoría',
  register_: 'Registro',
  clean_filter: 'Limpiar filtro',
  results_for: 'Resultados para',
  results: 'Resultados encontrados',
  sort: 'Orden',
  pageText: 'Página {0} de {1}',
  limpar: 'limpiar',
  cancelar: 'Cancelar',
  excluir: 'Excluir',
  editar: 'Editar',
  ver: 'Ver',
  salvar: 'Salvar',
  confirmar: 'Confirmar',
  enviar: 'Enviar',
  feito: 'Hecho',
  importar: 'Importar',
  criar: 'Crear',
  copiar: 'Copiar',
  copiar_para: 'Copiar a...',
  back: 'Volver',
  next: 'Siguiente',
  area: 'Área',
  sair: 'Salir',
  saca: 'Saca',
  get_in_touch: 'Entrar en contacto',
  which_channel_to_contact: '¿Con qué canal te gustaría ponerte en contacto?',
  commercial: 'Comercial',
  support: 'Soporte',
  relationship: 'Relación',
  adicionar: 'Elegir',
  select: 'Seleccione',
  desfazer: 'deshacer',
  enable: 'Habilitar',
  function_temporarily_unavailable: 'Función temporalmente no disponible',
  disable: 'Deshabilitar',
  she_enabled: 'Activada',
  she_disabled: 'Desactivada',
  he_enabled: 'Activado',
  he_disabled: 'Desactivado',
  enabled_s: 'Activado',
  disabled_s: 'Desactivado',
  she_disable: 'Deshabilitar',
  he_disable: 'Deshabilitar',
  utilized: 'Utilizada',
  contracted: 'Contratada',
  total_area: 'Área total',
  select_date: 'Seleccione una fecha',
  total: 'Total',
  quote: 'Cotización',
  active: 'Activo',
  inactive: 'Inactivo',
  select_period: 'Elige un período',
  select_another_period: 'Elige otro período',
  select_file: 'Elige un archivo',
  upload_file: 'Subir archivo',
  semeadura_em: 'Siembra en {0}',
  emergencia_em: 'Emergencia en',
  emergency_date: 'Fecha de emergencia',
  custo_total_hectares: 'Costo total por hectárea',
  aplicado_em: 'Aplicado en',
  desconhecido: 'Desconocido',
  campo_obrigatorio: 'Campo obligatorio',
  required: ' *Obligatorio',
  nome_muito_longo: 'Nombre muy largo',
  preencha_corretamente_campos: 'Rellene los campos correctamente',
  apply_filter: 'Aplicar filtro',
  nome: 'Nombre',
  title: 'Título',
  description: 'Descripción',
  email: 'Email',
  profile: 'Perfil',
  alta: 'Alta',
  media: 'Media',
  baixa: 'Baja',
  moeda: 'US$',
  price: 'Precio',
  required_price: 'Precio obligatorio',
  succesfuly_saved: 'Guardado con éxito',
  data_ideal: 'Fecha ideal',
  until: 'hasta',
  day: 'dia',
  days: 'días',
  dias_de_atraso: '{0} días de atraso',
  sem_registro: 'Fungicidas sin registro ante el organismo responsable',
  one_question_before_continue: 'Una pregunta antes de continuar',
  what_is_your_job_title_today: '¿Cuál es tu cargo hoy?',
  we_want_to_know_to_improve_your_experience:
    'Queremos saber para mejorar tu experiencia',
  date: 'Fecha',
  dias_semana: {
    hoje: 'hoy',
    ter: 'mar',
    qua: 'mie',
    qui: 'jue',
    sex: 'vie',
    sab: 'sab',
    dom: 'dom',
  },
  of: 'de',
  created_by: 'Criado por',
  edited_by: 'Editado por',
  print: 'Imprimir',
  priority: 'prioridad',
  secondary: 'secundario',
  learn_more: 'Obtenga más información en',
  did: 'realizó el',
  used: 'Usó',
  _to_: '{0} a {1}',
  thousand: 'mil',
  tour: {
    notes_navbar: 'Puede acceder a sus anotaciones a través de este menú.',
    filter_notes:
      'Puede filtrar sus anotaciones por <strong>categoría, lote, fecha y usuario</strong>.',
    create_note:
      'Para crear una nueva anotación, haga clic en el botón de nueva anotación.',
    menu_changed: 'El menu cambio, ahora puede accesarlo aqui',
    user_menu: 'Aqui tu puedes accesar informaciones sobre tu cuenta',
    skip: 'Dejar el tour',
    end: 'Finalizar',
  },
  message_area_exceeded:
    'Ha superado su área contratada. Desactivar haciendas y campos o contratar más area.',
  message_farm_disabled:
    'Esta hacienda está deshabilitada, no podrá editar ni crear nada. habilitar para usar',
  closure: 'Cierre',
  bought: 'Comprado',
  own: 'Propio',
  localization: 'Localización',
  vazio_sanitario: {
    vazio_sanitario: 'Vacío sanitario',
    disable_navbar_btn:
      'Cosecha inhabilitada debido al período de vacío sanitario.',
    termino_para_semeadura:
      'Esperando el final del período de vacío sanitario para la siembra.',
  },
  indicator: 'Indicador',
  square: 'Cuadrado',
  dollar_rate: 'Tasa de dolar',
  physical_market: 'Mercado Fisico',
  stock_chicago: 'Bolsa Chicago',
  commodity: 'Commodity',
  communication: 'Anuncios',
  toolbar: {
    title_modal_crop_bag_price: 'Precio de los cultivos',
    price_per_seed: 'Saca',
  },
  no_data_available: 'No hay datos disponibles',
  data_available_to_farm_enabled:
    'Datos disponibles solo para la hacienda habilitada',
  register: {
    register: 'Registro',
    price: 'Precio de registro',
    bag_price: 'Registrar el precio de la bolsa de cultivo',
    secundary_title_whitout_bag_price: 'Antes de registrarse',
    secundary_text_whitout_bag_price:
      'Para poder utilizar el paso de cosecha, primero debe registrar el precio de la cultura. Este valor le permitirá analizar el costo-beneficio de los programas',
    secundary_title_did_not_fill_all_harvest:
      'Algunas siembras no se acomodan. ¿Quieres continuar de todos modos?',
    after: 'Sim, registradora depois',
    sowing: 'Registrar siembras',
    sowing_register: 'Registra tu siembra',
    register_sowing: 'Registra tus siembra',
    secundary_title_whitout_sowing: 'Registra siembra',
    secundary_text_whitout_sowing:
      'Para realizar un seguimiento de sus aplicaciones y fechas, primero debe registrar sus siembras aquí en DigiFarmz haciendo clic en el botón a continuación.',
  },

  plan: {
    upgrade_plan: 'Actualiza tu plan',
    menu: {
      my_farm: 'Mi hacienda',
      planting: 'Siembras',
      notes: 'Notas',
      planning: 'Planificación',
      performance: 'Actuación',
      catalog: 'Catalogar',
      plot: 'Lote',
      no_result: 'No hay resultados',
    },
  },

  weather: {
    info: 'informacion del tiempo',
    without_weather: 'Estamos recopilando sus datos meteorológicos',
    wait_to_load:
      'Espere hasta 24 horas para recibir información meteorológica',
  },

  crop: {
    crop: 'Cultivo',
    soybean: 'Soja',
    wheat: 'Trigo',
    save: {
      price: {
        success: 'Precio registrado correctamente!',
        error: 'Error al registrar precio!',
      },
    },
  },

  profiles: {
    ADMIN: 'Administrador General',
    CONTA: 'Administrador de Cuenta',
    FARM: 'Ingeniero Agrónomo',
    ASSISTENTE_TECNICO: 'Observador',
    FINANCEIRO: 'Financiero',
    COMERCIAL: 'Comercial',
    ADM_CORP: 'Administrador Corporativo',
    VEND_CORP: 'Vendedor Corporativo',
    VEND_DGF: 'Gestor de Cupones',
    TEC_DGF: 'Gestor Racional',
    CON_REV: 'Consultor de Reventa',
    PESQUISA: 'Investigación',
    description: {
      ADMIN: 'Puede editar, ver y crear todos',
      CONTA: 'Puede editar, ver y crear todos',
      FARM: 'Puede editar todo excepto granjas y lotes.',
      ASSISTENTE_TECNICO: 'Solo lectura',
      FINANCEIRO: 'Financiero',
      COMERCIAL: 'Comercial',
      ADM_CORP: 'Administrador Corporativo',
      VEND_CORP: 'Vendedor Corporativo',
      VEND_DGF: 'Gestor de Cupones',
      TEC_DGF: 'Gestor Racional',
      CON_REV: 'Puede editar, ver y crear, excepto los usuarios',
      PESQUISA:
        'Puede visualizar usuarios, crear y editar programas, siembras, haciendas y lotes',
    },
  },

  files: {
    type_files_allowed:
      'Tipos de archivos permitidos: jpg, jpeg, png, heic, xlsx, xls, pdf, doc, docx',
    size_files_allowed: 'Tamaño máximo de archivo: 5MB',
  },

  variety: {
    list: 'LISTA DE CULTIVOS',
    hide_created: 'Esconder registradas',
    show_created: 'Mostrar registradas',
    new: 'Nuevo Cultivar',
    variety_info: 'Información de cultivo',
    create: 'Nuevo cultivar de {0}',
    already_created: 'YA REGISTRADA',
    select_variety: 'Seleccione un cultivar para ver información detallada',
    create_selected: 'Registrar seleccionadas',
    unavailable_create: 'REGISTRO NO DISPONIBLE',
    unavailable_wheat_region_title:
      'La región no es apta para el cultivo de {0}',
    unavailable_wheat_region_complement:
      'Su región no es recomendable para el cultivo de {0} según la zonificación agroclimática deste cultivo',
    not_recommended: 'NO INDICADO PARA LA REGIÓN',
    sort: {
      seed_name_az: 'Nombre (A-Z)',
      seed_name_za: 'Nombre (Z-A)',
      GMR_small: 'GMR (Menor)',
      GMR_strong: 'GMR (Mayor)',
      owner_az: 'Empresa (A-Z)',
      owner_za: 'Empresa (Z-A)',
    },
    seed_name: 'Nombre da cultivar',
    owner: 'Empresa',
    request_variety: 'Solicitar cultivar',
    request_not_catologed_variety: 'Solicitar cultivar no listado',
    not_cataloged_variety: 'Cultivar no listado',
    no_result:
      'Si no encontró el cultivar que estaba buscando, haga clic en el botón de abajo y haga una solicitud',
    no_result_title: 'No se encontraron resultados',
    planned: 'CULTIVARES YA PLANEADAS',
    not_planned: 'OTRAS CULTIVARES',
  },

  job_titles: {
    farm_administrator: 'Administrador(a) da Fazenda',
    agricultural_technical_consultancy: 'Consultoria Técnico Agrícola',
    manager: 'Gerente',
    agricultural_engineering_consultancy: 'Consultoria Engenharia Agrícola',
    machine_operator: 'Operador de Máquinas',
    operation_supervisor: 'Supervisor(a) de Operação',
    dealer: 'Revendedor(a)',
    researcher: 'Pesquisador(a)',
    student: 'Estudante',
    other: 'Outro',
  },

  season: {
    version_previous: 'Versiones de temporadas anteriores',
    version_actual: 'Versión actual',
    version: 'Versión',
  },

  safra: {
    actual: 'Cosecha actual',
    previous: 'Cosechas anteriores',
    safra: 'Cultivo',
    inativa: 'Cultivo inactivo.',
    alterar_safra: 'Alterar Cultivo',
    contratar_safra: 'Contratar Cultivo',
    register_previous_season: 'Registrarse Cultivo Anteriror',
  },
  anterior: 'anterior',
  previous: 'Anterior',
  permissao: {
    erro: 'Error al obtener permiso!',
  },
  ndvi: {
    dates_for_analysis: 'Imágenes para análisis',
    list: {
      no_content:
        'No se encontraron imágenes para análisis en el período seleccionado.',
    },
    level_label: 'Nivel NDVI:',
    low: 'Bajo',
    medium: 'Medio',
    high: 'Elevado',
    know_more: 'Más información sobre NDVI',
  },
  integration: {
    integrations: 'Integraciones',
    sync: 'Actualizar de nuevo',
    sync_manual: 'Actualizar manualmente',
    sync_success: 'Intento de sincronización exitoso.',
    sync_alert:
      'Las fincas y los campos aún se están importando de <strong>Climate FieldView</strong> y este proceso puede demorar algunas horas.',
    undo_integration: 'Deshacer',
    error: {
      title: '¡Ups! Parece que hubo un problema.',
      title_exceeded: 'No se pueden importar sus fincas y campos',
      description:
        'Tuvimos un problema al importar sus datos. Inténtalo de nuevo.',
      description_exceeded:
        'Parece que sus campos tienen más superficie de la que tiene contratada con nosotros. Pero no te preocupes, solo contacta a nuestro equipo de ventas y solicita la actualización.',
      upgrade: 'Aumentar mi area',
      try_again: 'Intentar nuevamente',
    },
    fieldview: {
      fieldview: 'Climate FieldView&trade;',
      title_part: 'Conecta tu cuenta con',
      title_success: 'Integración realizada con éxito',
      description:
        'Para conectarse tendrá que hacer clic en el botón de abajo e iniciar sesión en su cuenta de Climate FieldView&trade;, autorizando la conexión.',
      description_2: 'A PARTIR DE ALLÍ, LE TRAEREMOS LA SIGUIENTE INFORMACIÓN:',
      description_success:
        'Las granjas y los campos aún se están importando desde <b>Climate Fieldview</b> y este proceso puede demorar algunas horas. Puede hacer clic en actualizar en cualquier momento.',
      list: {
        farms: 'Haciendas',
        plots: 'Lotes',
      },
      btnBurger: 'Integrar com Climate FieldView&trade;',
      btn: 'Integrar Climate FieldView',
      btnStart: 'Volver a las granjas',
    },
  },

  user: {
    user: 'Usuarios',
    users: 'Usuario',
    register: 'Registrar usuario',
    edit: 'Editar usuario',
    register_success: 'Usuario registrado con éxito',
    delete_confirm: '¿Estás seguro de que quieres eliminar este usuario?',
    delete_success: 'Usuario eliminado con éxito',
  },

  farm: {
    farm: 'Hacienda',
    access_farm: 'Acceder a la hacienda',
    my_farm: 'Mi hacienda',
    select_farm: 'Seleccione una hacienda',
    see_all_farms: 'Ver todas las haciendas',
  },

  fazenda: {
    NoFarms: {
      welcome_no_farms: 'Aún no lo tienes hacienda registrada',
    },
    fazendas: 'Haciendas',
    excluir: {
      sucesso: 'Hacienda eliminada con éxito',
      erro: 'Error al eliminar hacienda',
      alerta: {
        titulo: 'Seguro?',
        mensagem:
          'Al eliminar la hacienda, los programas asociados se perderán. Esta operación es irreversible.',
      },
    },
    listar: {
      erro: 'Error al enumerar haciendas.',
    },
    carregar: {
      erro: 'Error al cargar haciendas.',
      erro_400: 'Error al cargar programas (status 400)',
    },
    salvar: {
      sucesso: 'Hacienda guardada con éxito.',
      erro: 'Error al guardar hacienda.',
    },

    enable: {
      success: 'Hacienda habilitada con éxito',
      warning:
        'Al deshabilitar la parcela, no podrás crear nuevas siembras. ¿Aún deseas continuar?',
      error:
        'Ya no puedes habilitar haciendas y lotes. Si aún desea habilitarlo, comuníquese con nuestro equipo de soporte.',
    },

    disable: {
      success: 'Hacienda deshabilitada con éxito.',
      warning:
        'Al deshabilitar la parcela, no podrás crear nuevas siembras. ¿Aún deseas continuar?',
    },

    farm_disabled: 'Hacienda deshabilitada',
    farm_disabled_message_banner_text1: 'Habilite la hacienda para registrar',
    farm_disabled_message_banner_text2:
      'Solo podrás registrar si tu hacienda está habilitada. Haga clic en el botón de abajo para administrar las haciendas.',
    manager_farms: 'Gerenciar haciendas',
    cadatro_fazenda: 'Registro de Hacienda',
    nome_da_fazenda: 'Nombre de la Hacienda',
    preco_por_saca: 'Precio por saca',
    create_farm: 'Registar Hacienda',
    manual_create_farm: 'Registre su hacienda manualmente',
  },

  cultivar: {
    cultivar: 'Cultivar',
    gmr: 'GMR',
    populacao: 'Población',
    populacao_entre: '{0} - {1} mil ',
    epoca_semeadura: 'Tiempo de Siembra',
    ciclo: 'Ciclo',
    ciclo_dias: '{0} días',
    regiao_adaptativa: 'Región adaptativa',
    titular: 'Titular',
    asset_owner: 'Titular',
    habito_crescimento: 'Hábito de crecimiento',
    growth_habit: {
      determinado: 'Determinado',
      semideterminado: 'Semideterminado',
      indeterminado: 'Indeterminado',
    },
    listar: {
      erro: 'Error al enumerar cultivares.',
    },
    excluir: {
      sucesso: 'Cultivar eliminado con éxito',
      erro: 'Error al eliminar cultivar',
    },
    salvar: {
      sucesso: 'Cultivar guardada con éxito',
      erro: 'Error al guardar cultivar',
    },
    solicitacao: {
      sucesso: 'Solicitación enviada con éxito',
      erro: 'Error al enviar solicitación',
    },
    contem: {
      erro: 'La hacienda no contiene el cultivar seleccionado',
    },
    carregar: {
      erro: 'Error al cargar cultivares',
    },
    copiar_para: {
      copiar_cultivar: 'Copiar Cultivar',
      help: 'Las cópias del cultivar están adaptadas para las haciendas seleccionadas. Por tanto, cada escenario puede presentar diferencias.',
      selecione_fazenda:
        'Seleccione las haciendas para recibir una cópia de la cultivar ',
      selecione_as_fazendas: 'Seleccione las Haciendas',
      sucesso: 'Cultivar copiada con éxito!',
      erro: 'Error al copiar cultivar',
    },
    solicitar: {
      msg: 'Solicitar cultivar no catalogada',
      label: 'Cultivar no catalogada',
    },
    not_recommended: 'No recomendado para su región',
    go_to_planning: 'Planificar el control de enfermedades',
    create_variety: 'Registrar cultivar',
  },

  seed: {
    seed: 'Semilla',
    seed_grower: 'semillero',
    seed_class: 'Clase de Semilla',
    select_seed_class: 'Seleccione una clase de semilla',
    select_seeder_system: 'Seleccione un sistema de semilla',
  },

  seed_class: {
    certified: 'Certificado',
    inspected: 'Inspeccionado',
    selected: 'Seleccionado',
  },

  seeder_system: {
    perforated_disk: 'Disco perforado',
    endless_thread: 'Hilo sin fin',
    pinching_fingers: 'Dedos prensatelas',
    perforated_belt: 'Correa perforada',
    fluted_cylinder: 'Cilindro estriado',
    pneumatic_seeder: 'Sembradora neumática',
    others: 'Otros',
  },

  pesticide: {
    pesticide: 'Defensivo',
  },

  adjuvant: {
    adjuvant: 'Adjuvante',
  },

  sowing: {
    new: 'Nueva Siembra',
    active_sowing: 'Siembra activa',
    out_of_time: 'Fuera de tiempo',
    message_heady_to_harvest:
      'Parece que tienes <strong>{0} siembras</strong> listos para cosechar. Registre la información de cosecha para despejar el área y registrar la nueva siembra en el campo.',
    message_sowing_heady_to_harvest:
      'Esta siembra ya está en época de cosecha. <strong>Registre la cosecha</strong>',
  },
  semeadura: {
    era_sowing: 'Era de Siembra',
    estimated_cycle_in_days: 'Ciclo estimado (en días)',
    germinative_power_in_percentage: 'Poder de germinación (en %)',
    seed_vigor_in_percentage: 'Vigor de la semilla (en %)',
    line_spacing: 'Espaciado entre líneas (en cm)',
    operating_speed: 'Velocidad de funcionamiento (en km/h)',
    population_lines: 'Población en la hilera (plantas/metro lineal)',
    date_realized: 'Fecha de siembra realizada',
    system_sowing: 'Sistema de siembra',
    semeadura: 'Siembras',
    carregar: {
      erro: 'Error al cargar siembras',
    },
    contem: {
      erro: 'La hacienda no contiene la siembra seleccionada',
    },
    excluir: {
      sucesso: 'Siembra eliminada con éxito',
      erro: 'Error al eliminar siembra.',
    },
    data_fora_semeadura: 'Fecha fuera del tiempo de siembra',
    sowing_data: 'Fecha de siembra',
    operation_data: 'Fecha de operación',
    origin: 'Origen',
    farm_sowing_time: 'Fecha fuera de la época de siembra de la finca',
    inactive_sowing_period: 'Periodo de siembra inactiva.',
    escolha_data_prevista: 'Elige una fecha prevista de siembra',
    cadastro: {
      selecione_cultivar: 'Elige una cultivar',
      warning:
        'Si existe un programa asociado, se han recalculado sus fechas, eficacias y enfermedades.',
      error: {
        estimated_cycle_min: 'El ciclo estimado debe ser mayor a 0',
        population_lines_min: 'La población debe ser mayor a 0',
        population_lines_max: 'La población debe ser más grande que 25,00',
        line_spacing_max: 'El espacio entre líneas debe ser como máximo 150',
        operating_speed_max:
          'La velocidad de operación debe ser como máximo 25',
        rest_sown_area: 'El área sembrada no puede ser más grande que el lote',
      },
      notify: {
        sown_area_is_null: 'El área sembrada nula',
        quantity_area_is_null: 'La cantidad de área nula',
        exceeded_area: 'Área excedida',
      },
    },
    sown_area: 'Área sembrada (ha)',
    sown_quantity: 'Cantidad sembrada (kg / ha)',
    inoculant: 'Inoculante',
  },

  validations: {
    in_range: 'El valor debe estar entre {min} y {max}',
    max_value: 'El valor debe ser menor que {max}',
    invalid_month: 'Mes inválido',
    invalid_year: 'Año inválido',
    month_previous_than_current: 'Mês menor que o atual',
    card_number_length: 'São necessários pelo menos 14 dígitos',
    text_only: 'O campo aceita somente texto',
    max_length: `O campo ultrapassa o limite de {0} caracteres`,
    invalid_email: 'Email inválido',
    invalid_document_number: 'Número de documento inválido',
    document_number_length: 'Número de documento debe tener 11 dígitos',
    invalid_card_number: 'Numero de tarjeta invalido',
  },

  soybean: {
    soybean: 'Soja',
    growth_habit: 'Hábito de crescimiento',
    population: 'Población',
    seed_tratament: 'Tratamiento de semillas',
  },

  growth_habit: {
    determinate: 'Determinado',
    semi_determinate: 'Semideterminado',
    indeterminate: 'Indeterminado',
  },

  program: {
    planned_programs: 'Programas planeados',
  },

  programa: {
    duplicate: {
      label: 'Duplicar',
    },
    programa: 'Programa',
    programas: 'Programas',
    novo_programa: 'Nuevo programa',
    nome_programa: 'Nombre del programa',
    carregar: {
      erro_400: 'Error al cargar programas (status 400)',
    },
    excluir: {
      sucesso: 'Programa eliminado con éxito',
      erro: 'Error al eliminar programa.',
      erro_400: 'Error al eliminar programa (status 400)',
    },
    salvar: {
      erro_400: 'Error al guardar programa (status 400)',
    },
    copiar_para: {
      titulo: 'Copiar programa',
      help: 'Las copias del programa son adaptadas para la hacienda y los cultivares seleccionados. Por lo tanto, cada escenario puede tener diferencias entre las eficacias.',
      selecione_cultivares: 'Elige las cultivares',
      marcar_todas: 'Elige todos',
      instrucao:
        'Elija los cultivares de cada hacienda para recibir una copia del programa.',
    },
    report: {
      produtos_doses: 'Productos y dosis por ha',
      projecao_para: 'Proyección para',
      em: 'en {0}',
    },
    doencas_prioridade: {
      stepper_titulo: 'Prioridad de enfermedades',
      doencas_recomendadas: 'Enfermedades recomendadas',
      descricao:
        'Para seguir las prioridades recomendadas por el sistema, mantenga la opción abajo activada. De lo contrario, seleccione una enfermedad prioritaria y dos enfermedades secundarias.',
      select_label: 'Enfermedades',
      select_error: 'Seleccione apenas {0} enfermedades',
      recomendadas: 'Recomendadas',
      personalizadas: 'Personalizadas',
    },
    numero_aplicacoes_sugeridas: 'Número de aplicaciones sugeridas',
    desassociar: 'Desvincular programa',
    white_mold_control_title: '¿Quieres controlar el <b>Moho Blanco</b>',
    white_mold_control_description:
      'Al marcar "Sí", consideraremos aplicaciones específicas para el moho blanco con un comportamiento específico respecto a otras',
    white_mold_total_sprayings: 'para Moho Blanco',
    permissions: {
      cant_create: 'No tienes permiso para agregar programas',
    },
  },
  severidade: {
    alta: 'Gravidad alta',
    media_alta: 'Gravidade Media-Alta',
    media: 'Gravidade media',
    media_baixa: 'Gravidade Media-Baja',
    baixa: 'Gravidade baja',
  },

  application: {
    closets_applications: 'Solicitudes para los próximos 15 días',
  },

  aplicacao: {
    choose_product: 'Elige un producto',
    aplicar: 'Aplicar',
    aplicacao: 'Aplicación',
    aplicacoes: 'Aplicaciones',
    recalculo_conforme_safra:
      'Para cada aplicación ejecutada, la efectividad y las fechas de aplicación se recalculan de acuerdo con la realidad del cultivo.',
    adicionar_aplicacao: 'Agregar aplicación',
    nova_aplicacao: 'Nueva aplicación',
    sugerir: {
      erro: 'Error al generar sugerencia',
    },
    doencas_prioridade: {
      select_label: 'Enfermedades principales',
      select_error: 'Seleccione apenas {0} enfermedades',
    },
  },
  diseases: {
    soybean_asian_soybean_rust: 'Roya',
    soybean_anthracnosis: 'Antracnosis',
    soybean_target_spot: 'Mancha Anillada',
    soybean_powdery_mildew: 'Oídio',
    soybean_cercospora_blight: 'Tizón Foliar',
    soybean_septoria_leaf_spot: 'Septoria',
    soybean_aerial_web_blight: 'Mela',
    soybean_white_mold: 'Moho Blanco',
    soybean_frogeye_leaf_spot: 'Mancha ojo de rana',
    soybean_diaporthe: 'Tizón por Diaporthe',
    wheat_leaf_rust: 'Roya',
    wheat_powdery_mildew: 'Oídio',
    wheat_yellow_leaf_spot: 'Mancha Amarilla',
    wheat_fusarium_head_blight: 'Giberela',
    wheat_blast: 'Brusone',
    alert: {
      white_mold_specific_spraying:
        'Esta aplicación es específica para <b>Moho Blanco</b>',
      white_mold_mix_spraying:
        'Esta aplicación es una mezcla para tratar <b>Moho Blanco</b> y otra enfermedad',
    },
  },
  spraying: {
    without_efficacy: 'Ineficaz',
    specific_white_mold_label: 'moho blanco',
  },
  pulverizacao: {
    sugestao: 'Sugerencia de pulverizaciones',
  },
  paineis: {
    dados_climaticos_aviso:
      'Datos meteorológicos y otras informaciones del dia a dia en el campo estarán disponibles.',
  },
  planejamento: {
    succesfuly_removed: 'planificación eliminada',
    erro: {
      erro_400: 'Error al eliminar planificación (status 400)',
    },
    salvar: {
      erro_400: 'Error al guardar planificación (status 400)',
    },
  },
  fungicida: {
    selecione_produto: 'Seleccione un producto.',
    informe_dose: 'Informe la dosis.',
    informe_preco: 'Informe el precio.',
    atualiza_eficacia:
      'Las eficacias son actualizadas después de guardar la aplicación.',
    doses: 'Dosis',
    doses_recomendadas: 'Dosis recomendadas',
    sem_registro: 'Sin registro',
    fungicida: 'Fungicida',
  },
  products_options: {
    mix: 'Mezcla',
    single: 'Simple',
    missing_targets:
      'Para ver las opciones de productos es obligatorio elegir al menos una enfermedad.',
    error: {
      loading: 'No se pudieron cargar las opciones de productos.',
    },
    show_options: 'Mostrar opciones de producto',
    hide_options: 'Ocultar opciones de producto',
    short_options: 'Opiciones de producto',
    list: {
      title: 'Productos',
    },
    description: {
      active_ingredients: 'Ingredientes activos',
      potencial_efficacy: 'Eficacia potencial',
      no_register_info:
        'Hay fungicidas que no tienen registro de todas las enfermedades indicadas, que están resaltadas en rojo! La información del producto sigue el prospecto registrado en MAPA (Ministerio de Agricultura, Ganadería y Abastecimiento).',
    },
  },
  eficacia: {
    eficacias_apresentadas:
      'Las eficacias presentadas ya consideran el uso de los adyuvantes recomendados por el fabricante',
    ajuda:
      'Utilizamos la fecha prevista para calcular la eficácia esperada por cada programa.',
    escolha_data_prevista: 'Elige la fecha esperada',
    escolha_severidade_esperada: 'Elige una severidad',
    data_prevista_semeadura: 'Fecha esperada de siembra',
    data_prevista_semeadura_nao_recomendada:
      'Fecha prevista de siembra fora de la epoca recomendada para la cultivar',
    severidade_esperada: 'Severidade esperada',
    sem_registro_ajuda:
      'En la aplicación hay fungicidas que no tienen registro de todas las enfermedades indicadas, ¡las cuales están resaltadas en rojo! La información del producto sigue la etiqueta registrada',
  },
  menu: {
    my_farm: 'Mi Hacienda',
    planejamento: 'Planificación',
    cultivares: 'Planificación de Variedades',
    programas: 'Programas',
    diseases: 'Planificación de Enfermedades',
    safra: 'Cultivo',
    semeaduras: 'Siembras',
    execucao: 'Ejecución',
    paineis: 'Paneles',
    notes: 'Notas',
    catalog: 'Catálogo',
    performance: 'Actuación',
    digifarmz_academy: 'DigiFarmz Academy',
    termos_de_uso: 'Terminos de uso',
    admin_panel: 'Panel Administrativo',
    fungicides: 'Fungicidas',
    nematicide: 'Nematicidas',
    plot: 'Lote',
    comparison: 'Comparación',
  },

  contato: {
    informativo: 'Contáctanos por teléfono o Whatsapp por número',
    msg_whatsapp_limit_enable_exceeded:
      '?text=%C2%A1Hola!+Quisiera+ayuda+porque+ya+no+puedo+habilitar+fincas+y+campos.+Uso+la+cuenta {0}.',
    msg_whatsapp_limit_area_exceeded:
      '?text=Hola+quisiera+solicitar+asistencia+porque+mi+%C3%A1rea+se+ha+excedido+la+que+uso+es {0} y+el+%C3%A1rea+que+tengo+es {1}.',
    msg_whatsapp_login_commercial:
      '?text=Hola%2C+me+gustar%C3%ADa+solicitar+ayuda+porque+quiero+registrarme+en+la+plataforma.',
    msg_whatsapp_login_support:
      '?text=Hola%2C+me+gustar%C3%ADa+solicitar+ayuda+porque+ya+no+puedo+iniciar+sesi%C3%B3n+en+la+plataforma.',
    msg_whatsapp_hire_plan:
      '?text=Hola%2C+me+gustar%C3%ADa+solicitar+ayuda+porque+quiero+contratar+el+plan+{0}.',
  },

  trocar_senha: {
    trocar_senha: 'Cambiar contraseña actual',
    senha_atual: 'Contraseña actual',
    nova_senha: 'Nueva contraseña',
    confirma_nova_senha: 'Confirmar nueva contraseña',
    valida_senha_vazio: 'La contraseña actual no puede estar vacía.',
    valida_senha_nova_vazio: 'La contraseña actual no puede estar vacía.',
    valida_senha_confirmacao_vazio:
      'Confirme que la contraseña no puede estar vacía.',
    aceito_termos: 'He leído y acepto los términos de uso del servicio',
    acesse_termos: 'Haga clic aquí para acceder al término.',
    senha_min: 'Contraseña actual no puede tener menos de {0} caracteres.',
    confirme_termos: 'Confirmar los términos de uso.',
    erro_generico: 'Error al cambiar contraseña.',
  },
  indique: {
    indique_amigo: 'Recomendar un amigo',
  },
  painel: {
    temperatura_c: 'Temperatura c°',
    temperatura_precipitacao: 'Temperatura y Precipitación (mm/día)',
    precipitation: {
      adjust_btn: 'Precipitación correcta',
    },
  },
  permission: {
    unauthorized_access: 'No tienes acceso a esta funcionalidad.',
    c: 'No tienes acceso a esta funcionalidad.',
  },
  pais: {
    pais: 'país',
  },
  estados: {
    estados: 'Estados',
    carregar: {
      erro: 'Error al cargar estados.',
    },
  },
  municipios: {
    municipios: 'Municipios',
    carregar: {
      erro: 'Error al cargar municipios.',
    },
  },
  talhoes: {
    search_latitude: 'Buscar latitude',
    search_longitude: 'Buscar longitude',
    rest_area_plot: 'Área restante del lote',
    quantity_total: 'Cantidad total',
    talhoes: 'Lotes',
    plot: 'Lote',
    salvar: {
      warning:
        'Formato KML desconocido o formateado sin especificaciones del sistema',
      sucesso: 'Hacienda guardada con éxito.',
    },
    cadastro: 'Registro de Lotes',
    text_criar: 'Vamos a crear los lotes de su hacienda ahora!',
    criar_pergunta: 'Crear lotes?',
    carregar_erro: 'Error al cargar lote',
    salvar_erro: 'Error al salvar lote',
    mapa: 'Mapa del Lote',
    nome_do_talhao: 'Nombre del Lote',
    remove_ponto: 'Quitar punto del mapa',
    excluir_title: 'Está seguro?',
    excluir_text:
      'Esta es una operación irreversible. Usted quiere excluir el lote?',
    excluir_erro: 'Error al excluir lote',
    text1: 'Mapear nuevos lotes',
    text2:
      'Usted puede agregar lotes a partir de archivos KML, o marcando el área del lote.',
    text3:
      'El archivo KML deve estar en el formato de Google y puede contener más de un lote.',
    text4: 'Cargar KML',
    text5: 'o',
    alert_salvar_talhao:
      'Para guardar el lote, agregue al menos 3 puntos en el mapa',
    area_exceeded:
      'Ha excedido el área total contratada de la cosecha, para continuar con los cambios, comuníquese con el comercial al número ',
    enable: {
      success: 'Lote habilitado con éxito.',
      warning: 'Solo puede habilitar el lote dos veces. Esta es {0} vez.',
      error:
        'Ya no puede habilitar el lote. Si aún desea desactivarlo, comuníquese con nuestro equipo de soporte.',
    },
    disable: {
      success: 'Lote deshabilitado con éxito.',
    },
    warnings: {
      insufficient_coordinates: 'El lote {0} tiene coordenadas incorrectas',
      insufficient_polygons: 'Número insuficiente de polígonos',
      no_latitude: 'El lote {0} no tiene latitudes',
      no_longitude: 'El lote {0} no tiene longitudes',
    },
    config_area: {
      success: 'Área actualizada con éxito',
    },
  },
  exception: {
    planting: {
      date_cant_be_modified: 'La fecha de siembra no se puede cambiar.',
      has_applied_sprayings: 'Esta siembra ya tiene aplicaciones realizadas.',
      has_vinculated_programs:
        'Las semillas con programas vinculados no se pueden eliminar.',
      has_program: 'La siembra ya tiene un programa vinculado.',
    },
    region: {
      invalid_variety: 'Región no válida para cultivar',
      no_recommendation: 'Región sin recomendación',
    },
    payment: {
      pending: 'Pagamento pendente.',
    },
    action_unauthorized: 'No se le permite realizar esta acción.',
    invalid_session: 'Su sesión ha expirado, inicie sesión nuevamente.',
    unexpected_error:
      'Disculpe las molestias, sucedió algo inesperado. Pero no se preocupe, nuestro equipo ya ha recibido una alerta y está trabajando para solucionar este problema.',
    crop: {
      forbidden: 'No se le permite acceder al cultivo seleccionado.',
    },
    plot: {
      has_planting: 'El campo ya tiene siembras registradas.',
      exceeded_area:
        'Excedió el área total contratada para el cultivo. Si desea contratar más, comuníquese con WhatsApp +55 51 99351-9481.',
      invalid_geometry:
        '¡Precaución! El diseño de campo debe representar una figura geométrica válida. Ajusta el mapa para completarlo. ',
      action_not_allowed:
        'Hacienda deshabilitada. Esta acción no está permitida.',
      has_planting_not_harvested: 'El campo tiene siembras no cosechadas.',
    },
    variety: {
      already_added_in_farm: 'Grow ya agregado a la granja.',
      already_added_in_program:
        'No es posible eliminar cultivares que tengan planificación, por favor elimine aquellos que contengan el cultivar.',
      already_already_added_in_planting:
        'No es posible eliminar este cultivar, ya que se está utilizando en una siembra en etapa de cultivo.',
    },
    report: {
      data_not_avaliable: 'La finca no tiene semillas cosechadas.',
      plot_no_registered: 'La finca no tiene parcelas registradas.',
      sowing_no_registered: 'Programa no tiene siembras registradas.',
    },
    season: {
      limited_access_enable_exceded:
        'Límite de habilitación por cosecha superado.',
    },
    farm: {
      has_registered_sowing: 'Hacienda está siendo utilizada en una siembra',
      farm_does_not_belong_in_user: 'Hacienda no pertenece al usuario',
      action_not_allowed:
        'Lote deshabilitado. No se le permite realizar esta acción.',
    },
    sowing: {
      has_registered_program: 'La siembra se está utilizando en un programa.',
      has_harvest: 'La siembra ya tiene una cosecha registrada',
    },
    spray: {
      invalid_white_mold_sprays:
        'DigiFarmz recomienda no más de 3 aplicaciones de Moho Blanco',
      date_before_last_spray:
        'Fecha anterior o igual a la última fecha de aplicación',
      date_before_minimal_sowing_date:
        'Fecha anterior a la fecha mínima de siembra',
      defensive_has_been_duplicated: 'Defensivo ha sido duplicado',
      not_last_exclude: 'La aplicaciones no es la última registrada.',
    },
    program: {
      has_spraying: 'Programa tiene aplicaciones',
    },
    file: {
      size_too_large: 'Tamaño máximo de archivo permitido 5MB',
      mimetype: 'Tipo de archivo no compatible',
    },
    invalid_credentials:
      'Su correo electrónico o contraseña es incorrecto. Por favor inténtalo de nuevo.',
    invalid_email:
      'Su correo electrónico es incorrecto. Por favor inténtalo de nuevo.',
    invalid_password: 'Contraseña incorrecta. Por favor inténtalo de nuevo.',
    password_confirmation_mismatch:
      'El campo "Confirmar nueva contraseña" es diferente del campo "Nueva contraseña".',
    too: {
      many: {
        attempts: 'Debes esperar 5 minutos antes de volver a intentarlo.',
      },
    },
    account: {
      activate: {
        active: {
          title: '¡Usuario ya activo!',
          description:
            'Utilice las credenciales de acceso que le enviamos al correo electrónico <strong>{{accountData.context.email}}</strong>. Después de iniciar sesión por primera vez, deberá restablecer su contraseña a una de su preferencia.',
        },
        activated: {
          title: '¡Su cuenta ha sido activada exitosamente!',
          description:
            'Enviamos un correo electrónico a <b>{{accountData.email}}</b> con las credenciales de primer acceso. Revisa tu bandeja de entrada.',
        },
        code_invalid: {
          title: '¡Código no válido!',
          description:
            'No pudimos activar su cuenta, el código de activación ha caducado. Haga clic en el botón a continuación para enviar un nuevo correo electrónico de activación.',
        },
        not_active:
          'Usuario inactivo! Para acceder a la plataforma necesitas activar tu cuenta. Si no encuentra el correo electrónico de activación en su bandeja de entrada o spam, haga clic en las opciones de contacto a continuación.',
        support:
          'Si no encuentra el correo electrónico en su bandeja de entrada o haga clic en las opciones de contacto a continuación. Si tiene algún problema, comuníquese con nuestro equipo de soporte por correo electrónico <b>suporte@digifarmz.com</b>.',
        resend_email: 'Reenviar correo electrónico',
      },
    },
  },

  execucao: {
    estadio_nao_recomendado:
      'Aplicación no recomendada debido al estadio fenológico y la presión de la enfermedad.',
    total_delay: 'Atrasos acumulados: {0} días',
    salvar: {
      erro_400: 'Error al salvar',
    },
    programa: {
      importar: 'Importar programa',
      novo: 'Nuevo programa',
    },
  },
  growth_stage: {
    disabled: 'No disponible',
    no_stage: 'Esperando la siembra',
    save_sucess: 'Observación guardada con éxito',
    save_failure: 'Error al guardar la observación',
    soybean: {
      ve: 'VE',
      vc: 'VC',
      v1: 'V1',
      v2: 'V2',
      v3: 'V3',
      v4: 'V4',
      v5: 'V5',
      v6: 'V6',
      v7_v9: 'V7-V9',
      r1: 'R1',
      r2: 'R2',
      r3: 'R3',
      r4: 'R4',
      r5: 'R5',
      r5_1: 'R5.1',
      r5_2: 'R5.2',
      r5_3: 'R5.3',
      r5_4: 'R5.4',
      r5_5: 'R5.5',
      r6: 'R6',
      r7: 'R7',
      r8: 'R8',
      r9: 'R9',
    },
    wheat: {
      0: 'Germinación',
      1: 'Primeras hojas',
      2: 'Inicio del perfilhamento',
      3: 'Afilhos formados',
      4: 'Crecimiento de la vaina',
      5: 'Pseudotallo desarrollado',
      6: 'Primer Nodo formado',
      7: 'Segundo Nudo formado',
      8: 'Hoja bandera visible',
      9: 'Lígula de la hoja bandera visible',
      10: 'Caucho',
      '10_1': 'Primer espiga visible',
      '10_2': '25% del espigamiento',
      '10_3': '50% del espigamiento',
      '10_4': '75% del espigamiento',
      '10_5': '100% del espigamiento',
      '10_5_1': 'Inicio de la floración',
      '10_5_2': 'Flor en el topo de la espiga',
      '10_5_3': 'Flor en la base de la espiga',
      '10_5_4': 'Final de la floración',
      '11_1': 'Granos lácteos',
      '11_2': 'Granos a granel',
      '11_3': 'Granos duros',
      '11_4': 'Maduración',
    },
  },
  irrigation: {
    no_irrigation: 'No se realizó irrigación',
  },
  irrigacao: {
    irrigacao: 'Irrigación',
    irrigacoes: 'Irrigaciones',
    salva_sucesso: 'Irrigación guardada con éxito',
    excluida_sucesso: 'Irrigación excluída con éxito',
    valida_data_time:
      'El inicio de la irrigación no puede ser menor que el término',
    valida_lamina: 'El volumen de la lamina no puede ser cero',
    salvar_erro: 'Error al guardar irrigación',
    data_inicio: 'Fecha de Inicio',
    data_fim: 'Fecha de Finalización',
    hora_inicio: 'Hora de Inicio',
    hora_fim: 'Hora de Finalización',
    precipitacao: 'Precipitación mm',
  },

  harvest: {
    harvested: 'cosechada',
    sucess_message: 'Cosecha de {0} sc/ha em {1}',
    register_harvest: 'Registrar cosecha',
    register_harvests: 'Registrar cosechas',
    lost_harvest: 'Perdí mi cosecha',
    lost: 'Cosecha perdida',
    did_dissecation: '¿Hizo la disección?',
    ready_to_harvest: 'Listo para cosechar',
  },

  colheita: {
    excluida_sucesso: 'Excluída con éxito',
    dessecacao: 'Desecación previa a la cosecha',
    data_colheita: 'Fecha de cosecha',
    sacas_por_hectare: 'Productividad (Sc/ha)',
    colheita: 'Cosecha',
    remover_colheita: 'Quitar cultivo',
  },

  stage: {
    stage: 'Estadio',
    stages: 'Estadios',
    real_stage: 'Estadio Actual',
    growth_stage: 'Estadio Fenológico',
    growth_stages: 'Estadios Fenológicos',
  },

  season_report: {
    bags_per_hectare: 'Sacos por hectárea',
    harvest_date: 'Fecha de cosecha',
    when_harvest: 'Cuando se cosecha',
    desiccation: 'Desecación',
    finished: 'Terminar',
    insert_harvest_data_title: 'Insertar Datos de Cosecha',
    jump: 'Salta',
  },

  qt_dias: 'dia | dias',
  tutorial: 'Tutorial',
  account: {
    release_area: 'Desocupar área',
  },
  maintenance: {
    title: 'La plataforma se encuentra actualmente en mantenimiento',
    description:
      'Para brindar un mejor servicio, estamos realizando algunas mejoras en la plataforma. Espere <b>unos minutos</b> e intente iniciar sesión de nuevo.',
    verify_availability: 'Verificar disponibilidad',
  },
  prescription_export: 'Descargar receta',
  prescription_export_next: 'Descargar siguiente receta',
  test: 'Prueba',
  academy: {
    title:
      'Parece que no tienes acceso a <span style="color: #39af49">DigiFarmz Academy</span>',
    description:
      'Pero no se preocupe, comuníquese con nuestro equipo de éxito del cliente para borrar su cuenta.',
    btn: 'Solicitar aceso',
    whatsapp:
      'Hola DigiFarmz, me gustaría solicitar acceso a DigiFarmz Academy, mi correo electrónico es ',
  },
  planting: {
    area_greather_than_plot:
      'El área sembrada no puede ser más grande que la área del lote restante',
    sown_area: 'Área Sembrada',
    sowing_date: 'Fecha de Sembrada',
  },
  required_fields: {
    special_characters: 'No se permiten acentos ni caracteres especiales',
    email: 'Este campo debe ser un correo electrónico válido',
    at_least_one: 'Debe haber al menos una hacienda',
    harvest: {
      date: 'La fecha de cosecha debe ser completada',
      productivity:
        'La productividad debe ser mayor que 0. Si perdiste, selecciona la opción "pérdida de cultivo".',
      validate_empty_fields_multi_harvest:
        'El campo de fecha y "sc/ha" es obligatorio si hay una cosecha. Si te lo perdiste, selecciona la opción "pérdida de cultivos".',
    },
  },

  disease_control: {
    action: 'O que queres plannear?',
    varieties: 'Variedades',
    diseases: 'Enfermidades',
    create_variety: 'Registrar variedad',
    select_variety: 'SELECCIONA VARIEDAD',
    select_crop: 'SELECCIONA CULTIVO',
    no_created_variety_title_subtitle: 'AÇÃO NECESSÁRIA',
    no_created_variety_title: 'Registre sus variedades primero',
    no_created_variety_text:
      'Para habilitar el control de enfermedades, primero debe registrar sus variedade',
  },

  date_without_year: 'DD/MM',

  card_info_plan: {
    title_main: 'Mejora tu plan',
    title_description:
      'Contrata nuevas funcionalidades para aumentar la productividad',
    subtitle_main: 'A partir de',
    button: 'Conoce los planes',
    money_unit: 'US$',
    per_month: '/mes',
  },
  help_button: {
    tour: 'Tour guiado',
    shortly: 'En breve',
    contact: {
      title: 'Hablar con servicio al cliente',
      link: 'https://api.whatsapp.com/send/?phone=5551994567668',
    },
  },
  freemium: {
    dialog: {
      title:
        'Has alcanzado el número máximo diario de accesos a la información del producto.',
      description:
        'Descubre nuestro <strong>plan Light</strong> y ten acceso ilimitado a toda la información del catálogo',
    },
    component: {
      title: 'Plano grátis',
      subtitle: 'Produtos visualizados diariamente',
      exceeded_limit: 'Limite diário atingido!',
      remaining_limit: 'restantes',
    },
    button: {
      title: 'Comprar catálogo',
    },
  },
  config_area: {
    header: 'Configuración de Área',
    title: 'Seleccione las parcelas que desea utilizar para el área contratada',
    article:
      'Utilizará el área contratada para disfrutar de los beneficios del plan Full en las parcelas seleccionadas.',
    area_contracted: 'Área contratada',
    area_remaning: 'Área restante',
    info: 'Las parcelas con un área mayor que el área contratada no podrán ser seleccionadas. Si lo desea, edite el área para ajustarse al área contratada restante.',
    wrapper_list: {
      area_total: 'Área total',
      edit_area: 'Editar área',
    },
    actions: {
      save: 'Guardar',
      cancel: 'Cancelar',
    },
  },

  alert_ndvi: {
    title: 'No es posible editar el área de esta parcela',
    message:
      'Las parcelas no podrán tener su área editada durante un período de 1 año si tienen NDVI contratado.',
    confirm: 'Entendido',
  },

  unsed_area: {
    title: 'No ha utilizado el área contratada para esta parcela',
    message:
      'Configure sus parcelas o utilice los beneficios del área contratada en otras parcelas.',
    action: 'Configurar área',
  },

  message_date_emergency: {
    dialog: {
      title: 'Atención',
      description:
        'Recomendamos registrar la siembra solo después de confirmar la emergencia, ya que una fecha incorrecta puede resultar en errores en las fechas de aplicación. Si es necesario, la fecha de emergencia se puede ajustar después del registro.',
    },
  },

  modal_full_plan: {
    title: '¡Felicidades! Ahora tienes el plan Full',
    message:
      'Configura tu área contratada para los lotes que tienes registrados y aprovecha todos los beneficios del plan Full',
    actions: {
      config_later: 'Configurar más tarde',
      config_now: 'Configurar ahora',
    },
  },
}
