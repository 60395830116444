<template>
  <card-weather class="weather-tab" :border="false" />
</template>

<script>
import CardWeather from '@/components/Weather/CardWeather.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'WeatherTab',

  components: {
    CardWeather,
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
  },
}
</script>
