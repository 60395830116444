<template>
  <v-dialog :value="true" persistent max-width="500">
    <template #activator="{ on }">
      <slot name="activator" :on="on"> </slot>
    </template>
    <v-card class="precipitation-metereology-modal">
      <v-card-title>
        <span class="modal__title">
          {{ $t('Weather.precipitation_hour') }}
        </span>
        <v-spacer />
        <v-btn icon @click="closeModal" right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="modal__content">
        <card-precipitation-meterology :weather="weather" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CardPrecipitationMeterology from '@/components/Weather/CardPrecipitationMeterology.vue'

export default {
  name: 'PrecipitationMetereologyModal',

  props: {
    weather: {
      default: () => [],
      type: Array,
    },
  },

  components: {
    CardPrecipitationMeterology,
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.precipitation-metereology-modal {
  .modal__title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #1a2b46;
  }
  .modal__content {
    padding: 0px 16px 24px 16px;
    background-color: #f5f5f5;
  }
}
</style>
