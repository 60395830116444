<template>
  <div class="category-tabs__wrapper">
    <v-tabs
      v-model="tab"
      class="category-tabs"
      color="#39AF49"
      background-color="transparent"
      show-arrows
    >
      <v-tab
        v-for="category in categories"
        :key="category.id"
        class="tab__width"
      >
        <font-awesome-icon class="mr-2" :icon="category.icon" />
        <span>{{ $t(category.label) }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="category in categories"
        :key="category.id"
        class="content__wrapper"
      >
        <slot :name="category.name"></slot>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: 'FieldInfosCard',

  data() {
    return {
      tab: null,
      categories: [
        {
          id: 1,
          name: 'planting',
          label: 'Plot.Categories.planting',
          icon: 'seedling',
        },
        {
          id: 2,
          name: 'notes',
          label: 'Plot.Categories.notes',
          icon: 'sticky-note',
        },
        {
          id: 3,
          name: 'weather',
          label: 'Plot.Categories.weather',
          icon: 'cloud',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.category-tabs__wrapper {
  min-height: 100%;
  height: 100%;
  max-height: 0px;
  max-width: 100%;
  border: 1px solid #e6e9e6;
  border-radius: 4px;

  .category-tabs {
    font-family: 'Rubik';
    font-size: 14px;
    border-bottom: 1px solid #e6e9e6;
    background: #fff;

    @include d(m) {
      span {
        font-size: 12px;
      }
    }
    .tab__width {
      width: calc(100% / 3);
    }
  }
  .content__wrapper {
    padding: 16px;

    @include d(t) {
      padding: 8px;
    }
    @include d(m) {
      padding: 8px;
    }
  }
}
::v-deep .v-tabs-items {
  overflow-y: auto;
  min-height: calc(100% - 50px);
  height: 50px;
}
::v-deep .v-tab--active {
  color: #39af49 !important;
  font-weight: 700;
  font-size: 14px;

  @include d(m) {
    font-size: 12px;
  }
}
::v-deep .v-slide-group__prev,
::v-deep .v-slide-group__next {
  @include d(m) {
    width: 28px;
    min-width: 28px;
  }
}
::v-deep .v-tab {
  text-transform: none !important;
}
</style>
