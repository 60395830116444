<template>
  <div class="df-flex-l df-flex-col chicago-card">
    <div class="df-flex-m df-flex-col">
      <h4 class="card__date">
        {{ quotation.date.formatDate() }}
      </h4>
      <div class="df-flex-xs df-flex-col">
        <div class="df-flex-xs align-baseline">
          <span class="card__currency-usd">US$</span>
          <div>
            <span class="card__usd-value">{{ brlPrice[0] }}</span>
            <span v-if="brlPrice[1]" class="card__usd-cents">
              ,{{ brlPrice[1].toString().substr(0, 2) }}
            </span>
          </div>
          <div>
            <font-awesome-icon
              v-if="quotation.percentage_variation >= 0"
              icon="arrow-up"
              size="xs"
              :color="colorIconSuccess"
            />
            <font-awesome-icon
              v-else
              icon="arrow-down"
              size="xs"
              :color="colorIconDanger"
            />
            <span class="card__percentage-variation">
              {{ porcentageVariation }}%
            </span>
          </div>
        </div>
        <div class="card__info-ordinance">
          {{ this.$t('closure') }} (US$ / {{ quotation.contract_type }})
        </div>
      </div>
    </div>
    <v-select
      v-model="quotation"
      :items="quotationsSelect"
      item-value="id"
      item-text="name"
      outlined
      return-object
      hide-details
      :label="$t('commodity')"
    />
  </div>
</template>

<script>
import colors from '@/assets/styles/__colors.module.scss'

export default {
  name: 'ChicagoCard',

  data() {
    return {
      colorIconDanger: colors.colorIconDanger,
      colorIconSuccess: colors.colorIconSuccess,
      quotations: [],
      quotation: {
        id: null,
        name: null,
        percentage_variation: 0,
        contract_type: null,
        close: 0,
        date: new Date().toLocaleDateString(),
      },
    }
  },

  watch: {
    quotations(val, old) {
      if (val !== old) {
        this.quotation = val[0]
      }
    },
  },

  async created() {
    await this.setQuotations()
  },

  computed: {
    brlPrice() {
      return this.quotation && this.quotation.close
        ? this.quotation.close.split('.')
        : ['0', '00']
    },
    quotationsSelect() {
      return [...this.quotations].sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )
    },
    porcentageVariation() {
      return this.quotation.percentage_variation
        ? this.quotation.percentage_variation.toFixed(1).replace('-', '')
        : 0
    },
  },

  methods: {
    async setQuotations() {
      this.$emit('loading-ordinaces', true)
      try {
        const { data } = await this.$api.quotations.getAllOrdinaces()
        this.quotations = data
      } catch (error) {
        console.error(error)
      } finally {
        this.$emit('loading-ordinaces', false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.chicago-card {
  padding: $spacing-l;

  .card__date {
    @include label-x-small;
    color: $color-text-neutral;
    font-weight: 600;
    text-transform: uppercase;
  }
  .card__currency-usd {
    @include paragraph-large;
    color: $color-text-disabled;
  }
  .card__usd-value {
    @include heading-large;
    font-weight: 600;
  }
  .card__usd-cents {
    @include label-small;
    font-weight: 600;
    text-transform: uppercase;
  }
  .card__percentage-variation {
    @include label-x-small;
    font-weight: 600;
  }
  .card__info-ordinance {
    @include label-x-small;
    color: $color-text-neutral;
  }
}
</style>
