<template>
  <div class="note-tab">
    <div v-if="loading" class="df-flex-xl df-flex-col">
      <div v-for="(_, index) in notesData.length || 3" :key="index">
        <v-skeleton-loader height="18px" type="text" width="50%" />
        <div class="df-flex-m df-flex-col">
          <v-skeleton-loader
            v-for="(_, jindex) in 2"
            :key="jindex"
            height="78px"
            type="image"
            width="auto"
          />
        </div>
      </div>
    </div>
    <div v-else-if="hasNotes" class="df-flex-xl df-flex-col">
      <div
        v-for="(dayGroup, index) in notesGroupedByDay"
        :key="index"
        class="df-flex-m df-flex-col"
      >
        <p class="list__title">
          {{ dayGroup.day }}
        </p>
        <div
          v-for="note in dayGroup.notes"
          :key="note.id"
          @click="redirectToNotes"
        >
          <v-card width="100%" outlined>
            <v-card-text class="df-flex-sm df-flex-col">
              <div class="df-flex-xm align-center">
                <span class="item__date"> {{ note.created_at }} / </span>
                <font-awesome-icon
                  :icon="iconCategory(note.category.name)"
                  class="item__category"
                />
                <span class="item__category">
                  {{ $t('Plot.Notes.categories.' + note.category.name) }}
                </span>
              </div>
              <span class="item__title">
                {{ note.title }}
              </span>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
    <div v-else class="item__title">
      {{ $t('Plot.Warnings.note') }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  formatDayOfMonth,
  formatDayDayOfMonth,
  formatHour,
} from '@/utils/formatters'

const CATEGORIES_ICON = {
  buy_and_sell: 'dollar-sign',
  climate: 'cloud-sun-rain',
  diseases: 'disease',
  fertilizing: 'heart',
  machines: 'tractor',
  nematodes: 'bacterium',
  others: 'file',
  pests: 'bug',
}

export default {
  name: 'NoteTab',

  props: {
    plotId: {
      type: String,
      default: null,
    },
  },

  async created() {
    await this.fetchNotes({ farmId: this.currentFarmId })
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    ...mapGetters('notes', ['notes', 'loading']),
    hasNotes() {
      return this.notesData.length > 0
    },
    notesData() {
      return this.notes.filter((note) => note.plot.id == this.plotId)
    },
    notesGroupedByDay() {
      const days = {}

      this.notesData.forEach((note) => {
        const date = note.created_at.formatDate()
        const day = this.$vuetify.breakpoint.xs
          ? formatDayOfMonth(note.created_at)
          : formatDayDayOfMonth(note.created_at)
        const time = formatHour(note.created_at)

        if (!days[date]) {
          days[date] = { day, notes: [] }
        }

        days[date].notes.push({ ...note, created_at: time })
      })

      return Object.values(days)
    },
  },

  methods: {
    ...mapActions('notes', ['fetchNotes']),
    iconCategory(category) {
      return CATEGORIES_ICON[category]
    },
    redirectToNotes() {
      this.$router.push({ path: '/notes' })
    },
  },
}
</script>

<style lang="scss" scoped>
.note-tab {
  .list__title {
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #788476;
  }
  .item__date {
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #788476;
  }
  .item__category {
    font-family: 'Rubik';
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    text-transform: uppercase;
    color: #4a76bc;
  }
  .item__title {
    font-family: 'Rubik';
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #1a2b46;
  }
}
</style>
