import { mockFlag } from '@/utils/mock'
import { APIFailureWrapper } from '@/utils/mock'
import { marketIndexes, physicalMarkets } from '@/modules/home/mock/marketSeeds'

const homeRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/markets',
      result: ({ queryParams }) => {
        const filteredMarkets = physicalMarkets.filter(
          (market) => market.crop_id == queryParams.crop_id
        )

        return APIFailureWrapper({
          content: filteredMarkets,
          errorMessage: 'Erro na listagem de ações',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/market-indexes',
      result: () => {
        return APIFailureWrapper({
          content: marketIndexes,
          errorMessage: 'Erro na listagem de informações dos mercados',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/dollar-quotation',
      result: () => {
        const dollar = { usd_brl: '6.00' }

        return APIFailureWrapper({
          content: dollar,
          errorMessage: 'Erro na listagem do valor do dólar',
        })
      },
    },
    'on'
  ),
]

export { homeRoutes }
