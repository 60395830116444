<template>
  <div class="header-no-farms">
    <div class="header__image">
      <img
        alt="no-farm"
        class="image"
        src="img/backgrounds/img-header-home-no-farms-1920.webp"
        srcset="
          img/backgrounds/img-header-home-no-farms-768.webp   768w,
          img/backgrounds/img-header-home-no-farms-1264.webp 1264w,
          img/backgrounds/img-header-home-no-farms-1920.webp 1920w
        "
        sizes="
          (max-width: 768px) 768px,
          (max-width: 1264px) 1264px,
          (max-width: 1920px) 1920px,
          1920px
        "
      />
      <div class="df-flex-m df-flex-col align-center image__title">
        <h1>
          {{ $t('Home.header.hello_user', [currentUser.name]) }} Digifarmz,
        </h1>
        <h2>{{ $t('fazenda.NoFarms.welcome_no_farms') }}</h2>
      </div>
    </div>
    <div class="df-flex-xxl justify-center header__content">
      <div
        class="df-flex-xl df-flex-col align-center content__add-farm"
        @click="createFarm"
      >
        <v-img alt="tractor" max-width="90px" src="icons/ic-tractor-icon.svg" />
        <div class="df-flex-sm df-flex-col align-center add-farm__text">
          <h3>
            {{ $t('fazenda.create_farm') }}
          </h3>
          <span>
            {{ $t('fazenda.manual_create_farm') }}
          </span>
        </div>
      </div>
      <div
        v-if="false"
        class="df-flex-xl df-flex-col align-center content__fieldview"
        @click="redirectToFieldview"
      >
        <v-img
          alt="fieldview"
          max-width="90px"
          src="img/logos/logo-fieldview.svg"
        />
        <div class="df-flex-sm df-flex-col align-center fieldview__text">
          <h3>
            {{ $t('Home.header.fieldview_import') }}
          </h3>
          <span>
            {{ $t('Home.header.conditional_import_farms_and_plots') }}
          </span>
        </div>
      </div>
    </div>
    <modal-fazenda
      v-if="dialogModalFarm"
      :dialog="dialogModalFarm"
      :farm-id="currentFarmId"
      @close="reloadFarmsList"
    />
  </div>
</template>

<script>
import ModalFazenda from '@/pages/fazendas/ModalFazenda.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HeaderNoFarms',

  mixins: [PermissionMixin],

  components: {
    ModalFazenda,
  },

  data() {
    return {
      dialogModalFarm: false,
    }
  },

  computed: {
    ...mapGetters('farms', ['currentFarm', 'currentFarmId']),
    ...mapGetters('user', ['currentUser']),
  },

  methods: {
    ...mapActions('farms', ['fetchFarms', 'firstFarmLoad', 'hasNoFarms']),
    createFarm() {
      this.dialogModalFarm = true
    },
    redirectToFieldview() {
      this.$router.push('/integracoes/fieldview')
    },
    async reloadFarmsList() {
      if (this.hasNoFarms) {
        await this.firstFarmLoad()
      } else {
        await this.fetchFarms()
      }
      this.dialogModalFarm = false
    },
  },
}
</script>

<style lang="scss" scoped>
.header-no-farms {
  max-width: 1440px;
  margin: 0px auto;
  padding: 0px 0px $spacing-xxl 0px;
  position: relative;

  .header__image {
    position: relative;
    height: 240px;
    border-radius: 0px 0px $spacing-sm $spacing-sm;
    overflow: hidden;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
    .image__title {
      position: absolute;
      top: calc(50% - 20px);
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 100%;

      @include d(m) {
        padding: 0px 12px;
      }
      h1 {
        @include heading-x-small;
        color: $color-text-primary;
      }
      h2 {
        @include heading-medium;
        color: $color-text-white;
        width: 400px;
        text-align: center;
      }
    }
  }
  .header__content {
    position: absolute;
    z-index: 2;
    top: 200px;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    width: 100%;

    .content__add-farm {
      padding: $spacing-xl;
      border: 1px solid $color-border;
      border-radius: $border-radius-sm;
      background-color: $color-background-white;
      height: fit-content;
      width: 380px;
      max-width: 380px;
      cursor: pointer;

      .add-farm__text {
        width: 200px;

        h3 {
          @include heading-x-small;
          text-align: center;
        }
        span {
          @include paragraph-small;
          color: $color-text-neutral;
          text-align: center;
        }
      }
    }
    .content__fieldview {
      padding: $spacing-xl;
      border: 1px solid $color-border;
      border-radius: $border-radius-sm;
      background-color: $color-background-white;
      height: fit-content;
      width: 380px;
      max-width: 380px;
      cursor: pointer;

      .fieldview__text {
        width: 200px;

        h3 {
          @include heading-x-small;
          text-align: center;
        }
        span {
          @include paragraph-small;
          color: $color-text-neutral;
          text-align: center;
        }
      }
    }
  }
}
</style>
