<template>
  <div class="df-flex-m df-flex-col card-dropdown">
    <h1 v-if="title" class="dropdown__header">
      {{ title }}
    </h1>
    <div class="df-flex-l df-flex-col dropdown__content">
      <div
        v-for="(object, index) in objects"
        :key="index"
        class="df-flex-xs align-center"
      >
        <p class="df-col-8 dropdown__item" @click="clickButton(object)">
          {{ object.name }}
        </p>
        <div v-if="iconTwo" class="df-col-2 text-end">
          <icon-with-info
            :info="object.attribute_two"
            :tooltip="tooltipTwo"
            :icon="iconTwo"
          />
        </div>
        <div class="text-end" :class="iconTwo ? 'df-col-2' : 'df-col-4'">
          <icon-with-info
            :info="object.attribute_one"
            :tooltip="tooltipOne"
            :index="index"
            :icon="iconOne"
          />
        </div>
      </div>
    </div>
    <div v-if="iconButton">
      <df-button
        class="df-button--primary"
        flank="fa-pull-left"
        text
        :icon="iconButton"
        @click="actionButton"
      >
        {{ textButton }}
      </df-button>
    </div>
  </div>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import IconWithInfo from '@/components/Info/IconWithInfo.vue'

export default {
  name: 'CardDropdown',

  components: {
    DfButton,
    IconWithInfo,
  },

  props: {
    objects: {
      required: true,
      type: Array,
    },
    textButton: {
      default: '',
      type: String,
    },
    iconButton: {
      default: '',
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
    iconOne: {
      default: '',
      type: String,
    },
    iconTwo: {
      default: '',
      type: String,
    },
    tooltipOne: {
      default: '',
      type: String,
    },
    tooltipTwo: {
      default: '',
      type: String,
    },
  },

  methods: {
    actionButton() {
      this.$emit('actionButton')
    },
    clickButton(object) {
      this.$emit('clickButton', object)
    },
  },
}
</script>

<style lang="scss" scoped>
.card-dropdown {
  padding: $spacing-l;
  background-color: $color-background-white;

  .dropdown__header {
    @include label-small;
    color: $color-text-disabled;
    text-transform: uppercase;
  }
  .dropdown__content {
    overflow-y: auto;
    max-height: 120px;

    .dropdown__item {
      @include label-small;
      color: $color-text-secondary;
      transition: color 0.3s ease;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        color: $color-text-primary;
      }
    }
  }
}
</style>
