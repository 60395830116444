import colors from '@/assets/styles/__colors.module.scss'
import radius from '@/assets/styles/__border-radius.module.scss'

class DfSkeletonLoader extends HTMLElement {
  static get observedAttributes() {
    return ['color', 'height', 'type', 'width']
  }

  attributeChangedCallback(name, _, newValue) {
    // Props must be passed in alphabetical order to take effect
    if (name === 'color') {
      this._color = newValue
    }
    if (name === 'height') {
      this._height = newValue
    }
    if (name === 'type') {
      this._type = newValue
    }
    if (name === 'width') {
      this._width = newValue
    }

    this.updateStyles()
  }

  connectedCallback() {
    this.updateStyles()
  }

  darkenHexColor(hex, percent) {
    hex = hex.replace('#', '')

    let r = parseInt(hex.substring(0, 2), 16)
    let g = parseInt(hex.substring(2, 4), 16)
    let b = parseInt(hex.substring(4, 6), 16)

    r = Math.max(0, Math.round(r * (1 - percent / 100)))
    g = Math.max(0, Math.round(g * (1 - percent / 100)))
    b = Math.max(0, Math.round(b * (1 - percent / 100)))

    return `#${r.toString(16).padStart(2, '0')}${g
      .toString(16)
      .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`
  }

  updateStyles() {
    this._loadingColor = this.darkenHexColor(this._color, 10)
    const loader = this.shadowRoot.querySelector('#loader')
    loader.style.height = this._height
    loader.style.width = this._width
    loader.style.background = `linear-gradient(
      105deg,
      ${this._color} 0%,
      ${this._color} 30%,
      ${this._loadingColor} 50%,
      ${this._color} 70%,
      ${this._color} 100%
    )`
    loader.style.backgroundSize = '200% 100%'
  }

  constructor() {
    super()
    this.attachShadow({ mode: 'open' })
    const { colorBackgroundDisabled } = colors
    const { borderRadiusSm } = radius

    this._color = colorBackgroundDisabled
    this._height = '40px'
    this._type = 'image'
    this._width = 'auto'

    this.shadowRoot.innerHTML = `
    <style scoped>
      .df-loader {
        border-radius: ${borderRadiusSm};
        animation: loading 2.5s infinite linear;
      }
      @keyframes loading {
        from {
          background-position: 200% 0;
        }
        to {
          background-position: -200% 0;
        }
      }
    </style>
    <div id="loader" class="df-loader"></div>
  `
  }
}

customElements.define('df-skeleton-loader', DfSkeletonLoader)
