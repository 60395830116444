import api from '@/services/api'
import { UNITED_STATES } from '@/utils/countries'

function initialState() {
  return {
    currentFarm: null,
    farms: null,
    loadingFarms: false,
  }
}

export default {
  namespaced: true,

  state: {
    initialState: initialState,
    currentFarm: initialState().currentFarm,
    farms: initialState().farms,
    loadingFarms: initialState().loadingFarms,
  },

  getters: {
    currentFarm(state) {
      return state.currentFarm
    },
    currentFarmId(state) {
      return state.currentFarm?.id
    },
    enabledFarm(state) {
      return state.currentFarm?.enabled
    },
    farms(state) {
      return state.farms
    },
    hasNoFarms(state) {
      return state?.farms?.length === 0
    },
    isFarmFromUSA(state) {
      return state.currentFarm?.location?.country?.id == UNITED_STATES
    },
    loadingFarms(state) {
      return state.loadingFarms
    },
  },

  mutations: {
    SET_CURRENT_FARM(state, farm) {
      state.currentFarm = farm
    },
    SET_FARMS(state, farms) {
      state.farms = farms
    },
    SET_LOADING_FARMS(state, loading) {
      state.loadingFarms = loading
    },
  },

  actions: {
    async firstFarmLoad(
      { commit, state, dispatch },
      routeFarmId,
      enableFetchFarms = true
    ) {
      if (enableFetchFarms) {
        await dispatch('fetchFarms')
      }

      if (!state.farms) {
        return
      }

      if (!state.currentFarm && !routeFarmId) {
        commit('SET_CURRENT_FARM', state.farms[0])
        return
      }

      if (!state.currentFarm && routeFarmId) {
        const farm =
          state.farms.find((farm) => farm.id == routeFarmId) ?? state.farms[0]

        commit('SET_CURRENT_FARM', farm)
        return
      }
    },
    setFarm({ commit, state }, farmId) {
      const farm = state.farms.find((farm) => farm.id == farmId)
      commit('SET_CURRENT_FARM', farm)
    },
    async fetchFarms({ state, commit, getters, dispatch }, routeFarmId) {
      commit('SET_LOADING_FARMS', true)
      try {
        const { data } = await api.fazendas.getFarms(
          'enabled:desc,created_at:desc'
        )

        commit('SET_FARMS', data)

        if (getters.currentFarmId) {
          const currentFarm = state.farms.find(
            (farm) => farm.id == getters.currentFarmId
          )

          if (currentFarm) {
            commit('SET_CURRENT_FARM', currentFarm)
            return
          }
          await dispatch('firstFarmLoad', routeFarmId, false)
        }
      } catch (error) {
        console.error(error)
        throw new Error('Erro ao buscar fazendas')
      } finally {
        commit('SET_LOADING_FARMS', false)
      }
    },
    async deleteFarm(
      { dispatch, getters, commit },
      { routeFarmId, excludingFarmId }
    ) {
      await api.fazendas.delete(excludingFarmId)

      if (getters.currentFarmId == excludingFarmId) {
        commit('SET_CURRENT_FARM', null)
      }

      commit(
        'SET_FARMS',
        getters.farms.filter(({ id }) => id != excludingFarmId)
      )

      await dispatch('firstFarmLoad', routeFarmId)
    },
    async enableDisableFarm({ dispatch }, { farmId }) {
      await api.fazendas.enableDisable(farmId)

      await dispatch('firstFarmLoad')
    },
    resetStore({ commit }) {
      commit('SET_CURRENT_FARM', initialState().currentFarm)
      commit('SET_FARMS', initialState().farms)
      commit('SET_LOADING_FARMS', initialState().loadingFarms)
    },
  },
}
